import React from "react";
import c from "classnames";

import { ReactComponent as Empty } from "../../assets/illustrations/empty.svg";
import { ReactComponent as Contact } from "../../assets/illustrations/contact.svg";
import { ReactComponent as NotFound } from "../../assets/illustrations/not-found.svg";
import { ReactComponent as Search } from "../../assets/illustrations/search.svg";
import { ReactComponent as Placeholder } from "../../assets/illustrations/placeholder.svg";
import { ReactComponent as NotSearched } from "../../assets/illustrations/not-searched.svg";
import { ReactComponent as Email } from "../../assets/illustrations/email.svg";
import { ReactComponent as Info } from "../../assets/illustrations/info.svg";

export const illustrations = {
  Empty,
  Contact,
  NotFound,
  Search,
  Placeholder,
  NotSearched,
  Email,
  Info,
};

type Size = "32" | "64";

const Illustration: React.FC<{ size?: Size; name: keyof typeof illustrations; style?: React.CSSProperties }> = ({
  size,
  name,
  style,
  ...props
}) => {
  // @ts-ignore
  const IllustrationSvg: any = illustrations[name];
  return (
    <div className={c("illustration", size ? `is-${size}` : null)} style={style} {...props}>
      <IllustrationSvg style={{ width: "100%" }} />
    </div>
  );
};

export default Illustration;
