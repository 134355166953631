import * as React from "react";
import { Heading } from "./system/Typography";
import { Box, Space } from "./system/Layout";
import { Container } from "../Page/Layout";

const StatusPage: React.FC<{
  type: string;
}> = ({ type, children }) => {
  return (
    <Container>
      <Box padding={4}>
        <Heading level={1} centered>
          {type}
        </Heading>
        <Space horizontal={4}>{children}</Space>
      </Box>
    </Container>
  );
};

export default StatusPage;
