import * as React from "react";
import "@sparetech/common/src/styles/styles.scss";
import c from "classnames";
import { Container } from "@sparetech/common/src/Page/Layout";

import Logo from "@sparetech/common/src/assets/logo.svg";
import { Heading, Text } from "@sparetech/common/src/components/system/Typography";
import Stack, { Box, HStack, Space } from "@sparetech/common/src/components/system/Layout";
import Illustration from "@sparetech/common/src/components/system/Illustration";

const App: React.FC<{ frontendVersion?: string }> = () => {
  return (
    <>
      <nav className={c("navbar")}>
        <Container fluid>
          <img className="left" src={Logo} alt={"Sparetech logo"} />
        </Container>
      </nav>
      <Container>
        <Space vertical={8} />
        <HStack space={8}>
          <Illustration name={"Info"} style={{ width: 500 }} />
          <Stack space={4}>
            <Space vertical={2} />
            <Heading level={1}>Partner App Deaktivierung</Heading>
            <Box padding={4}>
              <Stack padding={4} space={4}>
                <Text>
                  Wir bei SPARETECH wollen für unsere gemeinsamen Kunden mithilfe Ihrer Produktdaten den größtmöglichen
                  Mehrwert schaffen. Auf Grund der großen Nachfrage nach unserer Lösung fokussieren wir uns momentan auf
                  unsere Kundensoftware und stellen die Weiterentwicklung der Partner App vorerst ein.
                </Text>
                <Text>
                  Bei Fragen zu Ihrer Partnerschaft mit SPARETECH, melden Sie sich gerne bei ihrem SPARETECH
                  Ansprechpartner oder schreiben Sie eine E-Mail an unseren Head of Partner Management Hanno Treiber (
                  <a href={"mailto:hanno.treiber@sparetech.io"} className={"has-text-brand"}>
                    <Text>hanno.treiber@sparetech.io</Text>
                  </a>
                  ).
                </Text>
                <Text>
                  Katalog Updates können Sie uns über{" "}
                  <a href={"mailto:data@sparetech.io"} className={"has-text-brand"}>
                    <Text>data@sparetech.io</Text>
                  </a>{" "}
                  zukommen lassen.
                </Text>
              </Stack>
            </Box>
          </Stack>
        </HStack>
      </Container>
    </>
  );
};

// const App: React.FC<{ frontendVersion?: string }> = ({ frontendVersion = "" }) => {
//   return (
//     <AppShell
//       siteId={4}
//       frontendVersion={frontendVersion}
//       navLinks={[
//         { label: "Produktdaten", to: "/", exact: true },
//         { label: "Kundenanfragen", to: "/inquiries" },
//         { label: "Markteinblicke", to: "/insights" },
//       ]}
//       navMenu={[{ title: "Nutzerprofil", to: "/profile", icon: "User" }]}
//     >
//       <ProtectedRoute path={"/"} exact component={ProductOverview} />
//       <ProtectedRoute path={"/profile"} exact component={Profile} />
//       <ProtectedRoute path={"/inquiries"} exact component={InquiryOverview} />
//       <ProtectedRoute path={"/inquiries/:id"} exact component={InquiryDetail} />
//       <ProtectedRoute path={"/insights"} exact component={Insights} />
//       <ProtectedRoute path={"/products"} exact component={ProductOverview} />
//       <ProtectedRoute path={"/products/:id"} exact component={ProductDetails} />
//       <ProtectedRoute path={"/catalogs"} exact component={CatalogOverview} />
//       <ProtectedRoute path={"/catalogs/update"} exact component={CatalogUpdate} />
//     </AppShell>
//   );
// };

export default App;
