import React, { CSSProperties } from "react";
import c from "classnames";

export const Heading: React.FC<{ level: number; spaced?: boolean; centered?: boolean; color?: string }> = ({
  level,
  children,
  spaced,
  centered,
  color,
  ...props
}) => {
  const Elem = "h" + level;

  return (
    // @ts-ignore
    <Elem className={c({ spaced: spaced, "has-text-centered": centered, [`has-text-${color}`]: color })} {...props}>
      {children}
    </Elem>
  );
};
export const Text: React.FC<{
  small?: boolean;
  disabled?: boolean;
  spaced?: boolean;
  emphasized?: boolean;
  className?: string;
  style?: CSSProperties;
}> = ({ children, emphasized, small, disabled, spaced, className, style, ...props }) => (
  <span
    className={c(
      "text",
      {
        "text-small": small,
        "text-disabled": disabled,
        spaced: spaced,
        "text-emphasized": emphasized,
      },
      className,
    )}
    style={style}
    {...props}
  >
    {children}
  </span>
);
