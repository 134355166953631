import React, { createContext, useContext, useEffect, useState } from "react";
import auth, { ADMIN_ROLE, GHOST_ROLE } from "./Auth";
import { Api } from "../Api/Api";
import { Userinfo } from "../Api/generated/api";
import { SetStateFunction } from "../helpers/Types";
import * as Sentry from "@sentry/react";

interface AuthContextType {
  profile?: Userinfo;
  isChecking: boolean;
  isAdmin: boolean;
  isGhost: boolean;
  isAuthenticated?: boolean;
  errors?: UserProfileErrors;
  setProfile: SetStateFunction<Userinfo | undefined>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const useAuth = () => useContext(AuthContext);

const GHOST_USER_CLASS = "ghost-user";

export enum UserProfileErrors {
  NO_PROFILE,
  NO_BUSINESS_ENTITY,
  UNKNOWN,
  REFRESH_REQUIRED,
}

export const AuthProvider: React.FC<{ children: React.ReactChild }> = ({ children }) => {
  const [profile, setProfile] = useState<Userinfo | undefined>();
  const [errors, setErrors] = useState<UserProfileErrors | undefined>();
  const [isAuthenticated, setAuthenticated] = useState<boolean>();
  const [isAdmin, setAdmin] = useState(false);
  const [isGhost, setGhost] = useState(false);
  const [isChecking, setChecking] = useState(true);

  useEffect(() => {
    const getProfile = async (accessToken: string) => {
      const roles = auth.getRoles(accessToken);
      if (roles.includes(ADMIN_ROLE)) {
        setAdmin(true);
      } else {
        if (roles.includes(GHOST_ROLE)) {
          setGhost(true);
        }
        await Api.users
          .userinfo()
          .then(resp => {
            const responseProfile = resp?.data;
            setProfile(responseProfile);
            if (!responseProfile?.businessEntity) {
              setErrors(UserProfileErrors.NO_BUSINESS_ENTITY);
            }
            setChecking(false);
            Sentry.setTag("spt_client", responseProfile?.companyName);
          })
          .catch(error => {
            console.error(error);
            if (error?.status === 404) {
              setErrors(UserProfileErrors.NO_PROFILE);
            } else if (error?.status === 409) {
              setErrors(UserProfileErrors.REFRESH_REQUIRED);
            } else {
              setErrors(UserProfileErrors.UNKNOWN);
            }
            setChecking(false);
          });
      }
    };

    setChecking(true);
    auth
      .silentAuth()
      .then(() => {
        setAuthenticated(auth.isAuthenticated as boolean);
        getProfile(auth.accessToken as string);
      })
      .catch(_ => {
        setAuthenticated(false);
        setChecking(false);
      });
  }, []);

  useEffect(() => {
    const body = document.body.classList;
    if (isGhost) {
      body.add(GHOST_USER_CLASS);
    } else {
      body.remove(GHOST_USER_CLASS);
    }
  }, [isGhost]);

  return (
    <AuthContext.Provider
      value={{
        profile,
        isChecking,
        isAdmin,
        isAuthenticated,
        isGhost,
        errors,
        setProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
