import React from "react";
import c from "classnames";
import { Text } from "./Typography";
import Icon, { IconName } from "./Icon";
import { HStack } from "./Layout";
import { useTranslation } from "react-i18next";
import Can, { actions, resources } from "../../Auth/Can";

type ButtonAccents = "primary" | "secondary" | "tertiary";

interface GeneralButtonProps {
  // TODO: Decide whether we want to use only the strict definition
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void) | Function;
  submit?: boolean;
  title?: string;
  processing?: string | boolean;
  color?: string;
}

interface ButtonProps extends GeneralButtonProps {
  text: string | React.ReactNode;
  size?: "small" | "medium" | "big";
  disabled?: boolean;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
  icon?: IconName;
  iconPlacement?: "left" | "right";
}

interface BaseButtonProps extends GeneralButtonProps {
  accent?: ButtonAccents;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium" | "big";
  fullWidth?: boolean;
  hasIcon?: boolean;
}

interface TextButtonProps extends GeneralButtonProps {
  text: string | React.ReactNode;
  icon?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

interface IconButtonProps extends BaseButtonProps {
  accent: ButtonAccents;
  text: string;
  icon: IconName;
}

interface BetaButtonProps extends BaseButtonProps {
  style?: React.CSSProperties;
  text: string;
}

const ProcessingText: React.FC<{ text: string; color?: string }> = ({ text, color = "white" }) => {
  return (
    <Text style={{ display: "flex", alignItems: "center", minWidth: 100, width: "100%", justifyContent: "center" }}>
      {text}
      <Icon
        name="Logo"
        size={16}
        isSpinning={true}
        style={{ filter: `drop-shadow(0px 0px 1px ${color})`, marginLeft: 8 }}
      />
    </Text>
  );
};

export const Button: React.FC<BaseButtonProps> = ({
  children,
  accent,
  size = "medium",
  disabled,
  fullWidth = false,
  type = "button",
  onClick,
  hasIcon,
  processing,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={c(
        "button",
        accent,
        size,
        { "is-fullwidth": fullWidth },
        { "has-icon": hasIcon, processing: !!processing },
      )}
      disabled={disabled}
      type={processing ? "button" : type}
      onClick={processing ? undefined : (onClick as (e: React.MouseEvent<HTMLButtonElement>) => void)}
      {...props}
    >
      {processing ? (
        <ProcessingText text={typeof processing == "boolean" ? t("common:loading") : processing} color={props?.color} />
      ) : (
        children
      )}
    </button>
  );
};

export const BetaButton: React.FC<BetaButtonProps> = ({ text, ...props }) => (
  <Can I={actions.preview} a={resources.betaFeatures}>
    <Button accent={"primary"} {...props}>
      <HStack centered>
        <Icon name={"Beta"} iconSize={"is-32"} />
        <Text small>{text}</Text>
      </HStack>
    </Button>
  </Can>
);

export const IconButton: React.FC<IconButtonProps> = ({ text, accent, icon, ...props }: IconButtonProps) => (
  <Button accent={accent} hasIcon {...props}>
    <HStack centered>
      <Text small>{text}</Text> <Icon name={icon} iconSize={"is-16"} />
    </HStack>
  </Button>
);

export const PrimaryButton: React.FC<ButtonProps> = ({ text, icon, iconPlacement = "left", ...props }: ButtonProps) => (
  <Button accent={"primary"} color="#000" {...props}>
    {icon && iconPlacement === "left" && <Icon name={icon} style={{ marginRight: text ? 8 : 0 }} />}
    <Text small>{text}</Text>
    {icon && iconPlacement === "right" && <Icon name={icon} style={{ marginLeft: text ? 8 : 0 }} />}
  </Button>
);
export const SecondaryButton: React.FC<ButtonProps> = ({ text, ...props }) => (
  <Button accent={"secondary"} {...props}>
    <Text small>{text}</Text>
  </Button>
);
export const TertiaryButton: React.FC<ButtonProps> = ({ text, icon, iconPlacement, ...props }) => (
  <Button accent={"tertiary"} {...props}>
    {icon && iconPlacement === "left" && <Icon name={icon} style={{ marginRight: 8 }} />}
    <Text small>{text}</Text>
    {icon && iconPlacement === "right" && <Icon name={icon} style={{ marginLeft: 8 }} />}
  </Button>
);
export const TextButton: React.FC<TextButtonProps> = ({ text, disabled, onClick, fullWidth, ...props }) => (
  <button
    className={c("text-button", { "is-fullwidth": fullWidth })}
    disabled={disabled}
    type={"button"}
    onClick={onClick as (e: React.MouseEvent<HTMLButtonElement>) => void}
    {...props}
  >
    <Text>{text}</Text>
  </button>
);

interface ExtraProps {
  "data-tooltip"?: string;
  className?: string;
}

export const TextIconButton: React.FC<
  { icon: string; placement?: "left" | "right" } & TextButtonProps & ExtraProps
> = ({ icon, text, disabled, onClick, fullWidth, placement = "right", className, ...props }) => (
  <button
    className={c("text-button", { "is-fullwidth": fullWidth }, className)}
    disabled={disabled}
    type={"button"}
    style={{ display: "flex", alignItems: "center", paddingBottom: 24 }}
    onClick={onClick as (e: React.MouseEvent<HTMLButtonElement>) => void}
    {...props}
  >
    {placement === "left" && <Icon name={icon as any} style={{ marginRight: 8 }} />}
    <Text>{text}</Text>
    {placement === "right" && <Icon name={icon as any} style={{ marginLeft: 8 }} />}
  </button>
);

export const ToggleButton: React.FC<{
  toggled: boolean;
  onToggle: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  label?: string;
}> = ({ toggled, onToggle, label = "" }) => {
  return (
    <div className="toggle-wrapper">
      <p className="toggle-label">{label}</p>
      <div className="toggle-container" onClick={onToggle}>
        <div className={`toggle-button ${toggled ? "" : "toggle-disabled"}`} />
      </div>
    </div>
  );
};
