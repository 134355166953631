import "./AxiosConfig";
import {
  BusinessEntitiesApiFactory,
  DefaultApiFactory,
  FilesApiFactory,
  JobsApiFactory,
  MaterialMasterApiFactory,
  ProductsApiFactory,
  UsersApiFactory,
  PendingMaterialsApiFactory,
  ContactApiFactory,
  MaterialsApiFactory,
  ChangeRequestsApiFactory,
  LanguageApiFactory,
  ServiceApiFactory,
  MaterialExtensionsApiFactory,
  BomCheckApiFactory,
} from "./generated/api";

import {
  ClientsApiFactory,
  JobsApiFactory as AdminJobsApiFactory,
  UsersApiFactory as AdminUsersApiFactory,
  BusinessEntitiesApiFactory as AdminEntities,
  MaterialMasterApiFactory as AdminMaterialMaster,
  SptResultApiFactory,
  ManufacturersApiFactory as AdminManufacturersApiFactory,
  InquiriesApiFactory as AdminInquiriesApiFactory,
  ManufacturersApiFactory, // TODO: This seems wrong
  ProductsApiFactory as AdminProductsApiFactory,
  ProductImportApiFactory,
  ProductReportsApiFactory,
  MatchingApiFactory as AdminMatchingApiFactory,
  MatchingCandidatesApiFactory,
  SystemStatisticsApiFactory,
  LanguageApiFactory as AdminLanguageApiFactory,
  ServiceApiFactory as AdminServiceApiFactory,
} from "./generated/admin-api";

import {
  InquiriesApiFactory as ManufacturerInquiriesApiFactory,
  ProductsApiFactory as ManufacturerProductsApiFactory,
  UsersApiFactory as ManufacturerUsersApiFactory,
  CatalogsApiFactory as ManufacturerCatalogsApiFactory,
  StatisticsApiFactory as ManufacturerStatisticsApiFactory,
  ManufacturersApiFactory as ManufacturerManufacturersApiFactory,
  ServiceApiFactory as ManufacturerServiceApiFactory,
} from "./generated/manufacturer-api";

const apiServer = process.env.REACT_APP_API_SERVER;
const adminApiServer = process.env.REACT_APP_ADMIN_API_SERVER;

const apiHost = apiServer + "/v1";
const adminApiHost = adminApiServer + "/v1";
const manufacturerApiHost = apiServer + "/manufacturer/v1";

export const AdminApi = {
  businessEntities: AdminEntities(undefined, adminApiHost),
  catalogs: ManufacturerCatalogsApiFactory(undefined, adminApiHost),
  clients: ClientsApiFactory(undefined, adminApiHost),
  files: FilesApiFactory(undefined, apiHost),
  import: ProductImportApiFactory(undefined, adminApiHost),
  inquiries: AdminInquiriesApiFactory(undefined, adminApiHost),
  jobs: AdminJobsApiFactory(undefined, adminApiHost),
  manufacturers: AdminManufacturersApiFactory(undefined, adminApiHost),
  materialMaster: AdminMaterialMaster(undefined, adminApiHost),
  products: AdminProductsApiFactory(undefined, adminApiHost),
  reports: ProductReportsApiFactory(undefined, adminApiHost),
  sptResult: SptResultApiFactory(undefined, adminApiHost),
  users: AdminUsersApiFactory(undefined, adminApiHost),
  matching: AdminMatchingApiFactory(undefined, adminApiHost),
  matchingCandidates: MatchingCandidatesApiFactory(undefined, adminApiHost),
  statistics: SystemStatisticsApiFactory(undefined, adminApiHost),
  language: AdminLanguageApiFactory(undefined, adminApiHost),
  service: AdminServiceApiFactory(undefined, adminApiHost),
};

export const Api = {
  default: DefaultApiFactory(undefined, apiHost),
  users: UsersApiFactory(undefined, apiHost),
  businessEntities: BusinessEntitiesApiFactory(undefined, apiHost),
  materialMaster: MaterialMasterApiFactory(undefined, apiHost),
  products: ProductsApiFactory(undefined, apiHost),
  manufacturers: ManufacturersApiFactory(undefined, apiHost), // TODO: This seems wrong
  files: FilesApiFactory(undefined, apiHost),
  jobs: JobsApiFactory(undefined, apiHost),
  pendingMaterials: PendingMaterialsApiFactory(undefined, apiHost),
  contact: ContactApiFactory(undefined, apiHost),
  materials: MaterialsApiFactory(undefined, apiHost),
  changeRequest: ChangeRequestsApiFactory(undefined, apiHost),
  materialExtensions: MaterialExtensionsApiFactory(undefined, apiHost),
  language: LanguageApiFactory(undefined, apiHost),
  service: ServiceApiFactory(undefined, apiHost),
  bomCheck: BomCheckApiFactory(undefined, apiHost),
};

export const ManufacturerApi = {
  // default: ManufacturerDefaultApiFactory(undefined, manufacturerHost),
  manufacturers: ManufacturerManufacturersApiFactory(undefined, manufacturerApiHost),
  inquiries: ManufacturerInquiriesApiFactory(undefined, manufacturerApiHost),
  users: ManufacturerUsersApiFactory(undefined, manufacturerApiHost),
  products: ManufacturerProductsApiFactory(undefined, manufacturerApiHost),
  catalogs: ManufacturerCatalogsApiFactory(undefined, manufacturerApiHost),
  statistics: ManufacturerStatisticsApiFactory(undefined, manufacturerApiHost),
  service: ManufacturerServiceApiFactory(undefined, manufacturerApiHost),
};
