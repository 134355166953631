// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Sparetech API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface BomCheck
 */
export interface BomCheck {
  /**
   *
   * @type {number}
   * @memberof BomCheck
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BomCheck
   */
  name: string;
}
/**
 *
 * @export
 * @interface BusinessEntities
 */
export interface BusinessEntities {
  /**
   *
   * @type {Array<BusinessEntity>}
   * @memberof BusinessEntities
   */
  data: Array<BusinessEntity>;
}
/**
 *
 * @export
 * @interface BusinessEntity
 */
export interface BusinessEntity {
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BusinessEntity
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  level: number;
  /**
   *
   * @type {BusinessEntity}
   * @memberof BusinessEntity
   */
  ancestor?: BusinessEntity;
  /**
   *
   * @type {Array<BusinessEntity>}
   * @memberof BusinessEntity
   */
  descendants?: Array<BusinessEntity>;
  /**
   *
   * @type {boolean}
   * @memberof BusinessEntity
   */
  hasMaterialMaster: boolean;
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  users?: number;
  /**
   *
   * @type {string}
   * @memberof BusinessEntity
   */
  plant?: string;
}
/**
 *
 * @export
 * @interface ChangeRequest
 */
export interface ChangeRequest {
  /**
   *
   * @type {number}
   * @memberof ChangeRequest
   */
  id?: number;
  /**
   *
   * @type {MappedMaterial}
   * @memberof ChangeRequest
   */
  material: MappedMaterial;
  /**
   *
   * @type {number}
   * @memberof ChangeRequest
   */
  materialMasterId: number;
  /**
   *
   * @type {User}
   * @memberof ChangeRequest
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof ChangeRequest
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequest
   */
  createdAt?: string;
  /**
   *
   * @type {BusinessEntity}
   * @memberof ChangeRequest
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {Array<ChangeRequestValue>}
   * @memberof ChangeRequest
   */
  values: Array<ChangeRequestValue>;
  /**
   *
   * @type {Array<ChangeRequestResponse>}
   * @memberof ChangeRequest
   */
  responses: Array<ChangeRequestResponse>;
  /**
   *
   * @type {string}
   * @memberof ChangeRequest
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequest
   */
  sparetechId?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequest
   */
  plant?: string;
  /**
   *
   * @type {BomCheck}
   * @memberof ChangeRequest
   */
  bomCheck?: BomCheck;
}
/**
 *
 * @export
 * @interface ChangeRequestIds
 */
export interface ChangeRequestIds {
  /**
   *
   * @type {Array<number>}
   * @memberof ChangeRequestIds
   */
  ids: Array<number>;
}
/**
 *
 * @export
 * @interface ChangeRequestItem
 */
export interface ChangeRequestItem {
  /**
   *
   * @type {number}
   * @memberof ChangeRequestItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestItem
   */
  materialReference: string;
  /**
   *
   * @type {User}
   * @memberof ChangeRequestItem
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestItem
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestItem
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestItem
   */
  files?: string;
  /**
   *
   * @type {BusinessEntity}
   * @memberof ChangeRequestItem
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestItem
   */
  sparetechId?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestItem
   */
  plant?: string;
  /**
   *
   * @type {BomCheck}
   * @memberof ChangeRequestItem
   */
  bomCheck?: BomCheck;
}
/**
 *
 * @export
 * @interface ChangeRequestResponse
 */
export interface ChangeRequestResponse {
  /**
   *
   * @type {number}
   * @memberof ChangeRequestResponse
   */
  id?: number;
  /**
   *
   * @type {User}
   * @memberof ChangeRequestResponse
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestResponse
   */
  feedback?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestResponse
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestResponse
   */
  response: string;
}
/**
 *
 * @export
 * @interface ChangeRequestReview
 */
export interface ChangeRequestReview {
  /**
   *
   * @type {Array<number>}
   * @memberof ChangeRequestReview
   */
  ids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestReview
   */
  response: string;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestReview
   */
  feedback?: string;
}
/**
 *
 * @export
 * @interface ChangeRequestValue
 */
export interface ChangeRequestValue {
  /**
   *
   * @type {number}
   * @memberof ChangeRequestValue
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChangeRequestValue
   */
  fieldId: string;
  /**
   *
   * @type {object}
   * @memberof ChangeRequestValue
   */
  value: object;
}
/**
 *
 * @export
 * @interface ChangeRequests
 */
export interface ChangeRequests {
  /**
   *
   * @type {number}
   * @memberof ChangeRequests
   */
  numberOfResults: number;
  /**
   *
   * @type {string}
   * @memberof ChangeRequests
   */
  fileFieldTitle?: string;
  /**
   *
   * @type {Array<ChangeRequestItem>}
   * @memberof ChangeRequests
   */
  items: Array<ChangeRequestItem>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ContactType {
  ASAP = "ASAP",
  AtDate = "At Date",
}

/**
 *
 * @export
 * @interface EmbeddedProviderInfo
 */
export interface EmbeddedProviderInfo {
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  logo?: string;
}
/**
 *
 * @export
 * @interface FileDownload
 */
export interface FileDownload {
  /**
   *
   * @type {string}
   * @memberof FileDownload
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FileDownload
   */
  originalFileName: string;
  /**
   *
   * @type {string}
   * @memberof FileDownload
   */
  url: string;
}
/**
 *
 * @export
 * @interface FileReference
 */
export interface FileReference {
  /**
   *
   * @type {string}
   * @memberof FileReference
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FileReference
   */
  originalFileName: string;
}
/**
 *
 * @export
 * @interface FilterOption
 */
export interface FilterOption {
  /**
   *
   * @type {string}
   * @memberof FilterOption
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof FilterOption
   */
  value: string;
}
/**
 *
 * @export
 * @interface FormFieldData
 */
export interface FormFieldData {
  /**
   *
   * @type {string}
   * @memberof FormFieldData
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldData
   */
  value: string;
}
/**
 *
 * @export
 * @interface FormFieldMap
 */
export interface FormFieldMap {
  /**
   *
   * @type {string}
   * @memberof FormFieldMap
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldMap
   */
  formFieldName?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldMap
   */
  formFieldId: string;
  /**
   *
   * @type {Array<FormFieldData>}
   * @memberof FormFieldMap
   */
  data?: Array<FormFieldData>;
}
/**
 *
 * @export
 * @interface FormFieldMapping
 */
export interface FormFieldMapping {
  /**
   *
   * @type {Array<FormFieldMap>}
   * @memberof FormFieldMapping
   */
  data?: Array<FormFieldMap>;
}
/**
 *
 * @export
 * @interface FormLayout
 */
export interface FormLayout {
  /**
   *
   * @type {FormStage}
   * @memberof FormLayout
   */
  product: FormStage;
  /**
   *
   * @type {FormStage}
   * @memberof FormLayout
   */
  misc: FormStage;
  /**
   *
   * @type {FormStage}
   * @memberof FormLayout
   */
  inquiry: FormStage;
}
/**
 *
 * @export
 * @interface FormStage
 */
export interface FormStage {
  /**
   *
   * @type {string}
   * @memberof FormStage
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FormStage
   */
  icon?: string;
  /**
   *
   * @type {string}
   * @memberof FormStage
   */
  description?: string;
  /**
   *
   * @type {Array<Array<LayoutItem>>}
   * @memberof FormStage
   */
  items: Array<Array<LayoutItem>>;
  /**
   *
   * @type {object}
   * @memberof FormStage
   */
  config?: object;
}
/**
 *
 * @export
 * @interface HeaderMapping
 */
export interface HeaderMapping {
  /**
   *
   * @type {string}
   * @memberof HeaderMapping
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof HeaderMapping
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof HeaderMapping
   */
  fieldType?: string;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  file: any;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  file: string;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {any}
   * @memberof InlineResponse200
   */
  file?: any;
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {number}
   * @memberof Job
   */
  id: number;
  /**
   *
   * @type {User}
   * @memberof Job
   */
  author?: User;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  state?: string;
  /**
   *
   * @type {JobData}
   * @memberof Job
   */
  data?: JobData;
  /**
   *
   * @type {BusinessEntity}
   * @memberof Job
   */
  businessEntity?: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  estimate?: string;
  /**
   *
   * @type {Array<JobLog>}
   * @memberof Job
   */
  logs?: Array<JobLog>;
  /**
   *
   * @type {Array<JobResult>}
   * @memberof Job
   */
  results?: Array<JobResult>;
}
/**
 *
 * @export
 * @interface JobData
 */
export interface JobData {
  /**
   *
   * @type {FileReference}
   * @memberof JobData
   */
  file?: FileReference;
}
/**
 *
 * @export
 * @interface JobLog
 */
export interface JobLog {
  /**
   *
   * @type {User}
   * @memberof JobLog
   */
  author?: User;
  /**
   *
   * @type {JobLogData}
   * @memberof JobLog
   */
  change?: JobLogData;
  /**
   *
   * @type {string}
   * @memberof JobLog
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof JobLog
   */
  state?: string;
}
/**
 *
 * @export
 * @interface JobLogData
 */
export interface JobLogData {
  /**
   *
   * @type {string}
   * @memberof JobLogData
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof JobLogData
   */
  content?: string;
}
/**
 *
 * @export
 * @interface JobResult
 */
export interface JobResult {
  /**
   *
   * @type {JobResultStatistics}
   * @memberof JobResult
   */
  statistics: JobResultStatistics;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof JobResult
   */
  files: Array<FileReference>;
  /**
   *
   * @type {FileReference}
   * @memberof JobResult
   */
  sptResult?: FileReference;
  /**
   *
   * @type {string}
   * @memberof JobResult
   */
  date: string;
}
/**
 *
 * @export
 * @interface JobResultStatistics
 */
export interface JobResultStatistics {
  /**
   *
   * @type {number}
   * @memberof JobResultStatistics
   */
  duplicates?: number;
  /**
   *
   * @type {number}
   * @memberof JobResultStatistics
   */
  distinct?: number;
  /**
   *
   * @type {number}
   * @memberof JobResultStatistics
   */
  clarification?: number;
}
/**
 *
 * @export
 * @interface Jobs
 */
export interface Jobs {
  /**
   *
   * @type {Array<Job>}
   * @memberof Jobs
   */
  data: Array<Job>;
  /**
   *
   * @type {number}
   * @memberof Jobs
   */
  numberOfResults: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum JobsOrder {
  CreatedFirst = "Created First",
  CreatedLast = "Created Last",
  EditedLast = "Edited Last",
}

/**
 *
 * @export
 * @interface LabelValuePair
 */
export interface LabelValuePair {
  /**
   *
   * @type {string}
   * @memberof LabelValuePair
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof LabelValuePair
   */
  value: string;
}
/**
 *
 * @export
 * @interface LayoutItem
 */
export interface LayoutItem {
  /**
   *
   * @type {string}
   * @memberof LayoutItem
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof LayoutItem
   */
  property?: string;
  /**
   *
   * @type {string}
   * @memberof LayoutItem
   */
  heading?: string;
  /**
   *
   * @type {string}
   * @memberof LayoutItem
   */
  subHeading?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum LifeCycleFilter {
  Obsolete = "Obsolete",
  Redeemed = "Redeemed",
  Withsuccessor = "Withsuccessor",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum MachineOperatorRoles {
  ClientAdministrator = "Client Administrator",
  MaterialCreationPlanner = "Material Creation Planner",
  MaterialCreationUploader = "Material Creation Uploader",
  MaterialChangePlanner = "Material Change Planner",
  MaterialChangeUploader = "Material Change Uploader",
  DataCleansingPlanner = "Data Cleansing Planner",
  BetaUser = "Beta User",
}

/**
 *
 * @export
 * @interface ManufacturerAddress
 */
export interface ManufacturerAddress {
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  zipCode?: string;
}
/**
 *
 * @export
 * @interface ManufacturerConventions
 */
export interface ManufacturerConventions {
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  longDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  remarks?: string;
}
/**
 *
 * @export
 * @interface ManufacturerDetails
 */
export interface ManufacturerDetails {
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  legalName: string;
  /**
   *
   * @type {ManufacturerAddress}
   * @memberof ManufacturerDetails
   */
  address?: ManufacturerAddress;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  abbreviation: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  duns?: string;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  isSupplier: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  hasProducts: boolean;
  /**
   *
   * @type {ManufacturerConventions}
   * @memberof ManufacturerDetails
   */
  conventions?: ManufacturerConventions;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  parent?: string;
  /**
   *
   * @type {number}
   * @memberof ManufacturerDetails
   */
  numberOfProducts?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerDetails
   */
  synonyms: Array<string>;
  /**
   *
   * @type {ManufacturerIdentificationRules}
   * @memberof ManufacturerDetails
   */
  identification?: ManufacturerIdentificationRules;
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerDetails
   */
  relatedManufacturers?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  providesObsolescenceInfo: boolean;
}
/**
 *
 * @export
 * @interface ManufacturerIdentificationRules
 */
export interface ManufacturerIdentificationRules {
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerIdentificationRules
   */
  fields: Array<string>;
}
/**
 *
 * @export
 * @interface ManufacturerShort
 */
export interface ManufacturerShort {
  /**
   *
   * @type {string}
   * @memberof ManufacturerShort
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerShort
   */
  name: string;
}
/**
 *
 * @export
 * @interface MappedMaterial
 */
export interface MappedMaterial {
  /**
   *
   * @type {string}
   * @memberof MappedMaterial
   */
  materialReference: string;
  /**
   *
   * @type {number}
   * @memberof MappedMaterial
   */
  materialMasterId: number;
  /**
   *
   * @type {object}
   * @memberof MappedMaterial
   */
  content: object;
  /**
   *
   * @type {object}
   * @memberof MappedMaterial
   */
  notMapped?: object;
}
/**
 *
 * @export
 * @interface MappedMaterialsAndProducts
 */
export interface MappedMaterialsAndProducts {
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsAndProducts
   */
  databaseLabel: string;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsAndProducts
   */
  fieldTitle: string;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsAndProducts
   */
  materialTitle: string;
  /**
   *
   * @type {object}
   * @memberof MappedMaterialsAndProducts
   */
  materialSchema?: object;
  /**
   *
   * @type {object}
   * @memberof MappedMaterialsAndProducts
   */
  externalOptions?: object;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsAndProducts
   */
  materialValue: string;
  /**
   *
   * @type {ProductTitle}
   * @memberof MappedMaterialsAndProducts
   */
  productTitle: ProductTitle;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsAndProducts
   */
  productValue?: string;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsAndProducts
   */
  mappedProductValue?: string;
  /**
   *
   * @type {number}
   * @memberof MappedMaterialsAndProducts
   */
  fillOrder: number;
  /**
   *
   * @type {boolean}
   * @memberof MappedMaterialsAndProducts
   */
  protectedField?: boolean;
  /**
   *
   * @type {Array<object>}
   * @memberof MappedMaterialsAndProducts
   */
  productSuppliers?: Array<object>;
}
/**
 *
 * @export
 * @interface MappedMaterialsWithDuplicates
 */
export interface MappedMaterialsWithDuplicates {
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsWithDuplicates
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsWithDuplicates
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof MappedMaterialsWithDuplicates
   */
  value?: string;
}
/**
 *
 * @export
 * @interface Material
 */
export interface Material {
  /**
   *
   * @type {number}
   * @memberof Material
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  materialReference: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Material
   */
  cells: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Material
   */
  materialMasterId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Material
   */
  plants?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  rowPlant?: string;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasPendingChangeRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasProductSuggestion?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasDuplicate?: boolean;
  /**
   *
   * @type {MaterialProductSuggestionStatus}
   * @memberof Material
   */
  status?: MaterialProductSuggestionStatus;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  productState?: string;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasSuccessor?: boolean;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  foundBySearchStrategy?: string;
}
/**
 *
 * @export
 * @interface MaterialDetails
 */
export interface MaterialDetails {
  /**
   *
   * @type {number}
   * @memberof MaterialDetails
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MaterialDetails
   */
  materialReference: string;
  /**
   *
   * @type {number}
   * @memberof MaterialDetails
   */
  materialMasterId: number;
  /**
   *
   * @type {object}
   * @memberof MaterialDetails
   */
  content: object;
  /**
   *
   * @type {boolean}
   * @memberof MaterialDetails
   */
  hasPendingChangeRequest: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MaterialDetails
   */
  hasFormMapping: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialDetails
   */
  plants: Array<string>;
}
/**
 *
 * @export
 * @interface MaterialEvent
 */
export interface MaterialEvent {
  /**
   *
   * @type {number}
   * @memberof MaterialEvent
   */
  materialCreationId?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialEvent
   */
  changeRequestId?: number;
  /**
   *
   * @type {string}
   * @memberof MaterialEvent
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof MaterialEvent
   */
  timestamp: string;
  /**
   *
   * @type {object}
   * @memberof MaterialEvent
   */
  content?: object;
  /**
   *
   * @type {User}
   * @memberof MaterialEvent
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof MaterialEvent
   */
  businessEntity?: string;
  /**
   *
   * @type {number}
   * @memberof MaterialEvent
   */
  materialExtensionId?: number;
}
/**
 *
 * @export
 * @interface MaterialExtension
 */
export interface MaterialExtension {
  /**
   *
   * @type {number}
   * @memberof MaterialExtension
   */
  id?: number;
  /**
   *
   * @type {MappedMaterial}
   * @memberof MaterialExtension
   */
  material: MappedMaterial;
  /**
   *
   * @type {number}
   * @memberof MaterialExtension
   */
  materialMasterId: number;
  /**
   *
   * @type {BusinessEntity}
   * @memberof MaterialExtension
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {User}
   * @memberof MaterialExtension
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof MaterialExtension
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof MaterialExtension
   */
  createdAt?: string;
  /**
   *
   * @type {Array<ChangeRequestValue>}
   * @memberof MaterialExtension
   */
  values: Array<ChangeRequestValue>;
  /**
   *
   * @type {string}
   * @memberof MaterialExtension
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof MaterialExtension
   */
  plant?: string;
  /**
   *
   * @type {BomCheck}
   * @memberof MaterialExtension
   */
  bomCheck?: BomCheck;
}
/**
 *
 * @export
 * @interface MaterialExtensionIds
 */
export interface MaterialExtensionIds {
  /**
   *
   * @type {Array<number>}
   * @memberof MaterialExtensionIds
   */
  ids: Array<number>;
}
/**
 *
 * @export
 * @interface MaterialExtensionItem
 */
export interface MaterialExtensionItem {
  /**
   *
   * @type {number}
   * @memberof MaterialExtensionItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MaterialExtensionItem
   */
  materialReference: string;
  /**
   *
   * @type {BusinessEntity}
   * @memberof MaterialExtensionItem
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {User}
   * @memberof MaterialExtensionItem
   */
  user: User;
  /**
   *
   * @type {string}
   * @memberof MaterialExtensionItem
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof MaterialExtensionItem
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof MaterialExtensionItem
   */
  files?: string;
  /**
   *
   * @type {string}
   * @memberof MaterialExtensionItem
   */
  plant?: string;
  /**
   *
   * @type {boolean}
   * @memberof MaterialExtensionItem
   */
  editable?: boolean;
  /**
   *
   * @type {BomCheck}
   * @memberof MaterialExtensionItem
   */
  bomCheck?: BomCheck;
}
/**
 *
 * @export
 * @interface MaterialExtensions
 */
export interface MaterialExtensions {
  /**
   *
   * @type {number}
   * @memberof MaterialExtensions
   */
  numberOfResults: number;
  /**
   *
   * @type {string}
   * @memberof MaterialExtensions
   */
  fileFieldTitle?: string;
  /**
   *
   * @type {Array<MaterialExtensionItem>}
   * @memberof MaterialExtensions
   */
  items: Array<MaterialExtensionItem>;
}
/**
 *
 * @export
 * @interface MaterialMaster
 */
export interface MaterialMaster {
  /**
   *
   * @type {number}
   * @memberof MaterialMaster
   */
  id: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialMaster
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<Material>}
   * @memberof MaterialMaster
   */
  rows: Array<Material>;
  /**
   *
   * @type {number}
   * @memberof MaterialMaster
   */
  numberOfRows: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMaster
   */
  materialCreationColumn?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMaster
   */
  referenceColumn?: number;
}
/**
 *
 * @export
 * @interface MaterialMasterFilters
 */
export interface MaterialMasterFilters {
  /**
   *
   * @type {Array<LifeCycleFilter>}
   * @memberof MaterialMasterFilters
   */
  lifeCycleFilters?: Array<LifeCycleFilter>;
  /**
   *
   * @type {object}
   * @memberof MaterialMasterFilters
   */
  columns?: object;
}
/**
 *
 * @export
 * @interface MaterialMasterForm
 */
export interface MaterialMasterForm {
  /**
   *
   * @type {object}
   * @memberof MaterialMasterForm
   */
  schema: object;
  /**
   *
   * @type {FormLayout}
   * @memberof MaterialMasterForm
   */
  layout: FormLayout;
  /**
   *
   * @type {object}
   * @memberof MaterialMasterForm
   */
  externalOptions?: object;
}
/**
 *
 * @export
 * @interface MaterialMasterLog
 */
export interface MaterialMasterLog {
  /**
   *
   * @type {User}
   * @memberof MaterialMasterLog
   */
  user: User;
  /**
   *
   * @type {FileReference}
   * @memberof MaterialMasterLog
   */
  file: FileReference;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterLog
   */
  timestamp: string;
}
/**
 *
 * @export
 * @interface MaterialMasterLogs
 */
export interface MaterialMasterLogs {
  /**
   *
   * @type {Array<MaterialMasterLog>}
   * @memberof MaterialMasterLogs
   */
  items: Array<MaterialMasterLog>;
}
/**
 *
 * @export
 * @interface MaterialMasterSearchFilter
 */
export interface MaterialMasterSearchFilter {
  /**
   *
   * @type {string}
   * @memberof MaterialMasterSearchFilter
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterSearchFilter
   */
  filterColumn: string;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterSearchFilter
   */
  description?: string;
  /**
   *
   * @type {Array<FilterOption>}
   * @memberof MaterialMasterSearchFilter
   */
  options: Array<FilterOption>;
}
/**
 *
 * @export
 * @interface MaterialMasterSearchResults
 */
export interface MaterialMasterSearchResults {
  /**
   *
   * @type {number}
   * @memberof MaterialMasterSearchResults
   */
  numberOfResults: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialMasterSearchResults
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<Material>}
   * @memberof MaterialMasterSearchResults
   */
  results: Array<Material>;
}
/**
 *
 * @export
 * @interface MaterialMasterStatistics
 */
export interface MaterialMasterStatistics {
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterStatistics
   */
  timestamp: string;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  materialCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  greenCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  potentialCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  duplicateCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  obsoleteCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  redeemedCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  successorCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  missingCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  obsoleteWithSuccessorCount?: number;
  /**
   *
   * @type {SiemensStatistics}
   * @memberof MaterialMasterStatistics
   */
  siemensStatistics?: SiemensStatistics;
}
/**
 *
 * @export
 * @interface MaterialProductSuggestion
 */
export interface MaterialProductSuggestion {
  /**
   *
   * @type {string}
   * @memberof MaterialProductSuggestion
   */
  sparetechId: string;
  /**
   *
   * @type {ProductState}
   * @memberof MaterialProductSuggestion
   */
  productState: ProductState;
  /**
   *
   * @type {boolean}
   * @memberof MaterialProductSuggestion
   */
  hasSuccessor: boolean;
  /**
   *
   * @type {MaterialProductSuggestionStatus}
   * @memberof MaterialProductSuggestion
   */
  status: MaterialProductSuggestionStatus;
  /**
   *
   * @type {string}
   * @memberof MaterialProductSuggestion
   */
  createdAt: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialProductSuggestion
   */
  duplicates?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MaterialProductSuggestionStatus {
  SUGGESTED = "SUGGESTED",
  APPLIED = "APPLIED",
  EXTERNALLYADDED = "EXTERNALLY_ADDED",
}

/**
 *
 * @export
 * @interface NewBusinessEntity
 */
export interface NewBusinessEntity {
  /**
   *
   * @type {string}
   * @memberof NewBusinessEntity
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof NewBusinessEntity
   */
  parentId: number;
}
/**
 *
 * @export
 * @interface NewChangeRequest
 */
export interface NewChangeRequest {
  /**
   *
   * @type {string}
   * @memberof NewChangeRequest
   */
  materialReference: string;
  /**
   *
   * @type {Array<ChangeRequestValue>}
   * @memberof NewChangeRequest
   */
  values: Array<ChangeRequestValue>;
  /**
   *
   * @type {string}
   * @memberof NewChangeRequest
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof NewChangeRequest
   */
  sparetechId?: string;
  /**
   *
   * @type {string}
   * @memberof NewChangeRequest
   */
  plant?: string;
  /**
   *
   * @type {number}
   * @memberof NewChangeRequest
   */
  pendingMaterialId?: number;
}
/**
 *
 * @export
 * @interface NewJob
 */
export interface NewJob {
  /**
   *
   * @type {string}
   * @memberof NewJob
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof NewJob
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface NewMaterialExtension
 */
export interface NewMaterialExtension {
  /**
   *
   * @type {string}
   * @memberof NewMaterialExtension
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof NewMaterialExtension
   */
  materialReference: string;
  /**
   *
   * @type {Array<ChangeRequestValue>}
   * @memberof NewMaterialExtension
   */
  values: Array<ChangeRequestValue>;
  /**
   *
   * @type {string}
   * @memberof NewMaterialExtension
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof NewMaterialExtension
   */
  selectedPlant?: string;
  /**
   *
   * @type {number}
   * @memberof NewMaterialExtension
   */
  pendingMaterialId?: number;
}
/**
 *
 * @export
 * @interface NewPendingMaterial
 */
export interface NewPendingMaterial {
  /**
   *
   * @type {string}
   * @memberof NewPendingMaterial
   */
  status: string;
  /**
   *
   * @type {object}
   * @memberof NewPendingMaterial
   */
  content: object;
  /**
   *
   * @type {string}
   * @memberof NewPendingMaterial
   */
  searchText: string;
  /**
   *
   * @type {string}
   * @memberof NewPendingMaterial
   */
  sparetechId?: string;
  /**
   *
   * @type {string}
   * @memberof NewPendingMaterial
   */
  plant?: string;
}
/**
 *
 * @export
 * @interface NewUser
 */
export interface NewUser {
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  telephone?: string;
  /**
   *
   * @type {number}
   * @memberof NewUser
   */
  businessEntityId: number;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  office?: string;
  /**
   *
   * @type {Array<MachineOperatorRoles>}
   * @memberof NewUser
   */
  roles: Array<MachineOperatorRoles>;
}
/**
 *
 * @export
 * @interface PendingMaterial
 */
export interface PendingMaterial {
  /**
   *
   * @type {number}
   * @memberof PendingMaterial
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PendingMaterial
   */
  materialReference?: string;
  /**
   *
   * @type {number}
   * @memberof PendingMaterial
   */
  materialMasterId: number;
  /**
   *
   * @type {string}
   * @memberof PendingMaterial
   */
  status: string;
  /**
   *
   * @type {object}
   * @memberof PendingMaterial
   */
  content: object;
  /**
   *
   * @type {User}
   * @memberof PendingMaterial
   */
  author: User;
  /**
   *
   * @type {string}
   * @memberof PendingMaterial
   */
  createdAt: string;
  /**
   *
   * @type {boolean}
   * @memberof PendingMaterial
   */
  editable?: boolean;
  /**
   *
   * @type {BusinessEntity}
   * @memberof PendingMaterial
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof PendingMaterial
   */
  sparetechId?: string;
  /**
   *
   * @type {string}
   * @memberof PendingMaterial
   */
  plant?: string;
  /**
   *
   * @type {BomCheck}
   * @memberof PendingMaterial
   */
  bomCheck?: BomCheck;
  /**
   *
   * @type {boolean}
   * @memberof PendingMaterial
   */
  hasSearchResults: boolean;
}
/**
 *
 * @export
 * @interface PendingMaterialIds
 */
export interface PendingMaterialIds {
  /**
   *
   * @type {Array<number>}
   * @memberof PendingMaterialIds
   */
  ids: Array<number>;
}
/**
 *
 * @export
 * @interface PendingMaterials
 */
export interface PendingMaterials {
  /**
   *
   * @type {Array<PendingMaterial>}
   * @memberof PendingMaterials
   */
  items: Array<PendingMaterial>;
  /**
   *
   * @type {number}
   * @memberof PendingMaterials
   */
  numberOfResults: number;
  /**
   *
   * @type {Array<HeaderMapping>}
   * @memberof PendingMaterials
   */
  headerMapping: Array<HeaderMapping>;
}
/**
 *
 * @export
 * @interface PhoneContact
 */
export interface PhoneContact {
  /**
   *
   * @type {string}
   * @memberof PhoneContact
   */
  phone: string;
  /**
   *
   * @type {ContactType}
   * @memberof PhoneContact
   */
  contactType: ContactType;
  /**
   *
   * @type {string}
   * @memberof PhoneContact
   */
  date?: string;
}
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sparetechId: string;
  /**
   *
   * @type {ManufacturerShort}
   * @memberof Product
   */
  manufacturer: ManufacturerShort;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  gtin?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  shortDescription: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  longDescription: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  image?: string;
  /**
   *
   * @type {ProductSource}
   * @memberof Product
   */
  source: ProductSource;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  isConfigurable: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  successorId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  predecessors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  material?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  duplicateMaterial?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ProductAttribute
 */
export interface ProductAttribute {
  /**
   *
   * @type {string}
   * @memberof ProductAttribute
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isIdAttribute: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isRelevant: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductAttribute
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  parentId?: string;
}
/**
 *
 * @export
 * @interface ProductDetails
 */
export interface ProductDetails {
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  gtin?: string;
  /**
   *
   * @type {EmbeddedProviderInfo}
   * @memberof ProductDetails
   */
  provider: EmbeddedProviderInfo;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  supplierArticleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  source: string;
  /**
   *
   * @type {ProductCategory}
   * @memberof ProductDetails
   */
  category?: ProductCategory;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  expiryDate?: string;
  /**
   *
   * @type {ProductImage}
   * @memberof ProductDetails
   */
  image?: ProductImage;
  /**
   *
   * @type {SourcingInformation}
   * @memberof ProductDetails
   */
  sourcingInfo?: SourcingInformation;
  /**
   *
   * @type {Array<ProductValue>}
   * @memberof ProductDetails
   */
  values?: Array<ProductValue>;
}
/**
 *
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  caption?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  type: string;
}
/**
 *
 * @export
 * @interface ProductPrice
 */
export interface ProductPrice {
  /**
   *
   * @type {number}
   * @memberof ProductPrice
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ProductPrice
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof ProductPrice
   */
  originalPrice?: number;
}
/**
 *
 * @export
 * @interface ProductSearchAggregations
 */
export interface ProductSearchAggregations {
  /**
   *
   * @type {Array<ProductSearchFilter>}
   * @memberof ProductSearchAggregations
   */
  category?: Array<ProductSearchFilter>;
  /**
   *
   * @type {Array<ProductSearchFilter>}
   * @memberof ProductSearchAggregations
   */
  manufacturer?: Array<ProductSearchFilter>;
}
/**
 *
 * @export
 * @interface ProductSearchFilter
 */
export interface ProductSearchFilter {
  /**
   *
   * @type {string}
   * @memberof ProductSearchFilter
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearchFilter
   */
  numberOfProducts: number;
}
/**
 *
 * @export
 * @interface ProductSearchResults
 */
export interface ProductSearchResults {
  /**
   *
   * @type {ProductSearchAggregations}
   * @memberof ProductSearchResults
   */
  aggregations?: ProductSearchAggregations;
  /**
   *
   * @type {number}
   * @memberof ProductSearchResults
   */
  numberOfResults?: number;
  /**
   *
   * @type {Array<Product>}
   * @memberof ProductSearchResults
   */
  results: Array<Product>;
}
/**
 *
 * @export
 * @interface ProductSource
 */
export interface ProductSource {
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  origin: string;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  format: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ProductState {
  ACTIVE = "ACTIVE",
  REDEEMED = "REDEEMED",
  OBSOLETE = "OBSOLETE",
}

/**
 *
 * @export
 * @interface ProductTitle
 */
export interface ProductTitle {
  /**
   *
   * @type {string}
   * @memberof ProductTitle
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof ProductTitle
   */
  typeCode: string;
}
/**
 *
 * @export
 * @interface ProductValue
 */
export interface ProductValue {
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  unit?: string;
  /**
   *
   * @type {ProductAttribute}
   * @memberof ProductValue
   */
  attribute: ProductAttribute;
}
/**
 *
 * @export
 * @interface QuickView
 */
export interface QuickView {
  /**
   *
   * @type {Array<string>}
   * @memberof QuickView
   */
  header: Array<string>;
  /**
   *
   * @type {Array<QuickViewRow>}
   * @memberof QuickView
   */
  rows: Array<QuickViewRow>;
}
/**
 *
 * @export
 * @interface QuickViewRow
 */
export interface QuickViewRow {
  /**
   *
   * @type {string}
   * @memberof QuickViewRow
   */
  materialReference: string;
  /**
   *
   * @type {Array<string>}
   * @memberof QuickViewRow
   */
  material: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof QuickViewRow
   */
  product: Array<string>;
}
/**
 *
 * @export
 * @interface SelectableHeader
 */
export interface SelectableHeader {
  /**
   *
   * @type {Array<number>}
   * @memberof SelectableHeader
   */
  initialSelection: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof SelectableHeader
   */
  changeableHeaders: Array<number>;
}
/**
 *
 * @export
 * @interface SiemensStatistics
 */
export interface SiemensStatistics {
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  materialCount: number;
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  obsoleteCount: number;
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  redeemedCount: number;
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  obsoleteWithSuccessorCount: number;
}
/**
 *
 * @export
 * @interface SourcingInformation
 */
export interface SourcingInformation {
  /**
   *
   * @type {ProductPrice}
   * @memberof SourcingInformation
   */
  price?: ProductPrice;
  /**
   *
   * @type {string}
   * @memberof SourcingInformation
   */
  delivery?: string;
  /**
   *
   * @type {string}
   * @memberof SourcingInformation
   */
  link?: string;
}
/**
 *
 * @export
 * @interface UpdateMaterialExtension
 */
export interface UpdateMaterialExtension {
  /**
   *
   * @type {string}
   * @memberof UpdateMaterialExtension
   */
  status: string;
  /**
   *
   * @type {Array<ChangeRequestValue>}
   * @memberof UpdateMaterialExtension
   */
  values: Array<ChangeRequestValue>;
}
/**
 *
 * @export
 * @interface UpdatePendingMaterial
 */
export interface UpdatePendingMaterial {
  /**
   *
   * @type {number}
   * @memberof UpdatePendingMaterial
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePendingMaterial
   */
  status: string;
  /**
   *
   * @type {object}
   * @memberof UpdatePendingMaterial
   */
  content: object;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  telephone?: string;
  /**
   *
   * @type {BusinessEntity}
   * @memberof User
   */
  businessEntity?: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof User
   */
  manufacturerId?: string;
  /**
   *
   * @type {Array<MachineOperatorRoles>}
   * @memberof User
   */
  roles?: Array<MachineOperatorRoles>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  deleted?: boolean;
}
/**
 *
 * @export
 * @interface UserForm
 */
export interface UserForm {
  /**
   *
   * @type {object}
   * @memberof UserForm
   */
  schema: object;
  /**
   *
   * @type {UserFormLayout}
   * @memberof UserForm
   */
  layout: UserFormLayout;
}
/**
 *
 * @export
 * @interface UserFormLayout
 */
export interface UserFormLayout {
  /**
   *
   * @type {FormStage}
   * @memberof UserFormLayout
   */
  profile: FormStage;
}
/**
 *
 * @export
 * @interface Userinfo
 */
export interface Userinfo {
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  companyLogo?: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  documentationName?: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  documentationUrl?: string;
  /**
   *
   * @type {BusinessEntity}
   * @memberof Userinfo
   */
  businessEntity?: BusinessEntity;
}
/**
 *
 * @export
 * @interface UserinfoUpdate
 */
export interface UserinfoUpdate {
  /**
   *
   * @type {string}
   * @memberof UserinfoUpdate
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserinfoUpdate
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserinfoUpdate
   */
  telephone?: string;
}
/**
 *
 * @export
 * @interface Users
 */
export interface Users {
  /**
   *
   * @type {Array<User>}
   * @memberof Users
   */
  data: Array<User>;
  /**
   *
   * @type {boolean}
   * @memberof Users
   */
  ssoOnly?: boolean;
}

/**
 * BomCheckApi - axios parameter creator
 * @export
 */
export const BomCheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload file and create multiple materials
     * @param {string} fileId
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialsFromBomCheck(fileId: string, selectedPlant?: string, options: any = {}): RequestArgs {
      // verify required parameter 'fileId' is not null or undefined
      if (fileId === null || fileId === undefined) {
        throw new RequiredError(
          "fileId",
          "Required parameter fileId was null or undefined when calling createMaterialsFromBomCheck.",
        );
      }
      const localVarPath = `/bom-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fileId !== undefined) {
        localVarQueryParameter["fileId"] = fileId;
      }

      if (selectedPlant !== undefined) {
        localVarQueryParameter["selectedPlant"] = selectedPlant;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download excel template that will be used on client bom check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excelTemplate(options: any = {}): RequestArgs {
      const localVarPath = `/bom-check/excel-template`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all BOM checks for a material master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBomChecks(options: any = {}): RequestArgs {
      const localVarPath = `/bom-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BomCheckApi - functional programming interface
 * @export
 */
export const BomCheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload file and create multiple materials
     * @param {string} fileId
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialsFromBomCheck(
      fileId: string,
      selectedPlant?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BomCheck> {
      const localVarAxiosArgs = BomCheckApiAxiosParamCreator(configuration).createMaterialsFromBomCheck(
        fileId,
        selectedPlant,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Download excel template that will be used on client bom check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excelTemplate(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = BomCheckApiAxiosParamCreator(configuration).excelTemplate(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all BOM checks for a material master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBomChecks(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BomCheck>> {
      const localVarAxiosArgs = BomCheckApiAxiosParamCreator(configuration).getAllBomChecks(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BomCheckApi - factory interface
 * @export
 */
export const BomCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Upload file and create multiple materials
     * @param {string} fileId
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialsFromBomCheck(fileId: string, selectedPlant?: string, options?: any) {
      return BomCheckApiFp(configuration).createMaterialsFromBomCheck(fileId, selectedPlant, options)(axios, basePath);
    },
    /**
     *
     * @summary Download excel template that will be used on client bom check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excelTemplate(options?: any) {
      return BomCheckApiFp(configuration).excelTemplate(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all BOM checks for a material master
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBomChecks(options?: any) {
      return BomCheckApiFp(configuration).getAllBomChecks(options)(axios, basePath);
    },
  };
};

/**
 * BomCheckApi - object-oriented interface
 * @export
 * @class BomCheckApi
 * @extends {BaseAPI}
 */
export class BomCheckApi extends BaseAPI {
  /**
   *
   * @summary Upload file and create multiple materials
   * @param {string} fileId
   * @param {string} [selectedPlant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BomCheckApi
   */
  public createMaterialsFromBomCheck(fileId: string, selectedPlant?: string, options?: any) {
    return BomCheckApiFp(this.configuration).createMaterialsFromBomCheck(
      fileId,
      selectedPlant,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Download excel template that will be used on client bom check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BomCheckApi
   */
  public excelTemplate(options?: any) {
    return BomCheckApiFp(this.configuration).excelTemplate(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all BOM checks for a material master
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BomCheckApi
   */
  public getAllBomChecks(options?: any) {
    return BomCheckApiFp(this.configuration).getAllBomChecks(options)(this.axios, this.basePath);
  }
}

/**
 * BusinessEntitiesApi - axios parameter creator
 * @export
 */
export const BusinessEntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a business entity
     * @param {NewBusinessEntity} newBusinessEntity Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessEntity(newBusinessEntity: NewBusinessEntity, options: any = {}): RequestArgs {
      // verify required parameter 'newBusinessEntity' is not null or undefined
      if (newBusinessEntity === null || newBusinessEntity === undefined) {
        throw new RequiredError(
          "newBusinessEntity",
          "Required parameter newBusinessEntity was null or undefined when calling createBusinessEntity.",
        );
      }
      const localVarPath = `/business_entities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewBusinessEntity" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newBusinessEntity !== undefined ? newBusinessEntity : {})
        : newBusinessEntity || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary create new job in the scope of a business entity
     * @param {number} id
     * @param {NewJob} [newJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJob(id: number, newJob?: NewJob, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling createJob.");
      }
      const localVarPath = `/business_entities/{id}/jobs`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewJob" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newJob !== undefined ? newJob : {})
        : newJob || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Business Entity Endpoint
     * @summary get a business entity
     * @param {number} id
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntity(id: number, withUserCount?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getBusinessEntity.");
      }
      const localVarPath = `/business_entities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withUserCount !== undefined) {
        localVarQueryParameter["withUserCount"] = withUserCount;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Business Entity Endpoint
     * @summary get all users of a business entity
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntityUsers(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getBusinessEntityUsers.",
        );
      }
      const localVarPath = `/business_entities/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user data creation form
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserForm(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getUserForm.");
      }
      const localVarPath = `/business_entities/{id}/user-form`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Business Entity Endpoint
     * @summary update a business entity
     * @param {number} id
     * @param {BusinessEntity} [businessEntity] Update a business entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessEntity(id: number, businessEntity?: BusinessEntity, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateBusinessEntity.");
      }
      const localVarPath = `/business_entities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"BusinessEntity" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(businessEntity !== undefined ? businessEntity : {})
        : businessEntity || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BusinessEntitiesApi - functional programming interface
 * @export
 */
export const BusinessEntitiesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a business entity
     * @param {NewBusinessEntity} newBusinessEntity Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessEntity(
      newBusinessEntity: NewBusinessEntity,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).createBusinessEntity(
        newBusinessEntity,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary create new job in the scope of a business entity
     * @param {number} id
     * @param {NewJob} [newJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJob(
      id: number,
      newJob?: NewJob,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).createJob(id, newJob, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Business Entity Endpoint
     * @summary get a business entity
     * @param {number} id
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntity(
      id: number,
      withUserCount?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getBusinessEntity(
        id,
        withUserCount,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Business Entity Endpoint
     * @summary get all users of a business entity
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntityUsers(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getBusinessEntityUsers(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user data creation form
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserForm(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserForm> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getUserForm(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Business Entity Endpoint
     * @summary update a business entity
     * @param {number} id
     * @param {BusinessEntity} [businessEntity] Update a business entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessEntity(
      id: number,
      businessEntity?: BusinessEntity,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).updateBusinessEntity(
        id,
        businessEntity,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BusinessEntitiesApi - factory interface
 * @export
 */
export const BusinessEntitiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary create a business entity
     * @param {NewBusinessEntity} newBusinessEntity Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessEntity(newBusinessEntity: NewBusinessEntity, options?: any) {
      return BusinessEntitiesApiFp(configuration).createBusinessEntity(newBusinessEntity, options)(axios, basePath);
    },
    /**
     *
     * @summary create new job in the scope of a business entity
     * @param {number} id
     * @param {NewJob} [newJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createJob(id: number, newJob?: NewJob, options?: any) {
      return BusinessEntitiesApiFp(configuration).createJob(id, newJob, options)(axios, basePath);
    },
    /**
     * Business Entity Endpoint
     * @summary get a business entity
     * @param {number} id
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntity(id: number, withUserCount?: boolean, options?: any) {
      return BusinessEntitiesApiFp(configuration).getBusinessEntity(id, withUserCount, options)(axios, basePath);
    },
    /**
     * Business Entity Endpoint
     * @summary get all users of a business entity
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntityUsers(id: number, options?: any) {
      return BusinessEntitiesApiFp(configuration).getBusinessEntityUsers(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get user data creation form
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserForm(id: number, options?: any) {
      return BusinessEntitiesApiFp(configuration).getUserForm(id, options)(axios, basePath);
    },
    /**
     * Business Entity Endpoint
     * @summary update a business entity
     * @param {number} id
     * @param {BusinessEntity} [businessEntity] Update a business entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessEntity(id: number, businessEntity?: BusinessEntity, options?: any) {
      return BusinessEntitiesApiFp(configuration).updateBusinessEntity(id, businessEntity, options)(axios, basePath);
    },
  };
};

/**
 * BusinessEntitiesApi - object-oriented interface
 * @export
 * @class BusinessEntitiesApi
 * @extends {BaseAPI}
 */
export class BusinessEntitiesApi extends BaseAPI {
  /**
   *
   * @summary create a business entity
   * @param {NewBusinessEntity} newBusinessEntity Client information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public createBusinessEntity(newBusinessEntity: NewBusinessEntity, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).createBusinessEntity(newBusinessEntity, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary create new job in the scope of a business entity
   * @param {number} id
   * @param {NewJob} [newJob]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public createJob(id: number, newJob?: NewJob, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).createJob(id, newJob, options)(this.axios, this.basePath);
  }

  /**
   * Business Entity Endpoint
   * @summary get a business entity
   * @param {number} id
   * @param {boolean} [withUserCount] With user count
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getBusinessEntity(id: number, withUserCount?: boolean, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getBusinessEntity(
      id,
      withUserCount,
      options,
    )(this.axios, this.basePath);
  }

  /**
   * Business Entity Endpoint
   * @summary get all users of a business entity
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getBusinessEntityUsers(id: number, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getBusinessEntityUsers(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get user data creation form
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getUserForm(id: number, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getUserForm(id, options)(this.axios, this.basePath);
  }

  /**
   * Business Entity Endpoint
   * @summary update a business entity
   * @param {number} id
   * @param {BusinessEntity} [businessEntity] Update a business entity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public updateBusinessEntity(id: number, businessEntity?: BusinessEntity, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).updateBusinessEntity(
      id,
      businessEntity,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ChangeRequestsApi - axios parameter creator
 * @export
 */
export const ChangeRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Apply all the change request into ERP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAllChangeRequest(options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/apply`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Apply the change request into ERP
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyChangeRequest(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling applyChangeRequest.");
      }
      const localVarPath = `/change-requests/{id}/apply`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Apply multiple change request into ERP
     * @param {ChangeRequestIds} [changeRequestIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyMultipleChangeRequests(changeRequestIds?: ChangeRequestIds, options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/apply-multiple`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ChangeRequestIds" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeRequestIds !== undefined ? changeRequestIds : {})
        : changeRequestIds || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a change request
     * @param {NewChangeRequest} [newChangeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createChangeRequest(newChangeRequest?: NewChangeRequest, options: any = {}): RequestArgs {
      const localVarPath = `/change-requests`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewChangeRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newChangeRequest !== undefined ? newChangeRequest : {})
        : newChangeRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all open change request and update its status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllChangeRequests(options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export a change request and update its status
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportChangeRequest(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling exportChangeRequest.");
      }
      const localVarPath = `/change-requests/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export multiple request and update their status
     * @param {ChangeRequestIds} [changeRequestIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultipleChangeRequests(changeRequestIds?: ChangeRequestIds, options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/export-multiple`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ChangeRequestIds" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeRequestIds !== undefined ? changeRequestIds : {})
        : changeRequestIds || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all change requests
     * @param {number} [limit]
     * @param {number} [page]
     * @param {number} [businessEntityId]
     * @param {string} [userId]
     * @param {string} [type]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllChangeRequests(
      limit?: number,
      page?: number,
      businessEntityId?: number,
      userId?: string,
      type?: string,
      plant?: string,
      bomCheckId?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/change-requests`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (businessEntityId !== undefined) {
        localVarQueryParameter["businessEntityId"] = businessEntityId;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (plant !== undefined) {
        localVarQueryParameter["plant"] = plant;
      }

      if (bomCheckId !== undefined) {
        localVarQueryParameter["bomCheckId"] = bomCheckId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a change request
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequest(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getChangeRequest.");
      }
      const localVarPath = `/change-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available authors of change requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequestAuthors(options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/filters/authors`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available Business Entities of change requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequestBusinessEntities(options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/filters/businessEntities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Review a change request
     * @param {ChangeRequestReview} [changeRequestReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewChangeRequest(changeRequestReview?: ChangeRequestReview, options: any = {}): RequestArgs {
      const localVarPath = `/change-requests/review`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ChangeRequestReview" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeRequestReview !== undefined ? changeRequestReview : {})
        : changeRequestReview || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChangeRequestsApi - functional programming interface
 * @export
 */
export const ChangeRequestsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Apply all the change request into ERP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAllChangeRequest(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).applyAllChangeRequest(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Apply the change request into ERP
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyChangeRequest(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).applyChangeRequest(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Apply multiple change request into ERP
     * @param {ChangeRequestIds} [changeRequestIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyMultipleChangeRequests(
      changeRequestIds?: ChangeRequestIds,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).applyMultipleChangeRequests(
        changeRequestIds,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Creates a change request
     * @param {NewChangeRequest} [newChangeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createChangeRequest(
      newChangeRequest?: NewChangeRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeRequest> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).createChangeRequest(
        newChangeRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export all open change request and update its status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllChangeRequests(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).exportAllChangeRequests(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export a change request and update its status
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportChangeRequest(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).exportChangeRequest(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export multiple request and update their status
     * @param {ChangeRequestIds} [changeRequestIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultipleChangeRequests(
      changeRequestIds?: ChangeRequestIds,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).exportMultipleChangeRequests(
        changeRequestIds,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all change requests
     * @param {number} [limit]
     * @param {number} [page]
     * @param {number} [businessEntityId]
     * @param {string} [userId]
     * @param {string} [type]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllChangeRequests(
      limit?: number,
      page?: number,
      businessEntityId?: number,
      userId?: string,
      type?: string,
      plant?: string,
      bomCheckId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeRequests> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).getAllChangeRequests(
        limit,
        page,
        businessEntityId,
        userId,
        type,
        plant,
        bomCheckId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a change request
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequest(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeRequest> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).getChangeRequest(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available authors of change requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequestAuthors(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).getChangeRequestAuthors(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available Business Entities of change requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequestBusinessEntities(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntities> {
      const localVarAxiosArgs =
        ChangeRequestsApiAxiosParamCreator(configuration).getChangeRequestBusinessEntities(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Review a change request
     * @param {ChangeRequestReview} [changeRequestReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewChangeRequest(
      changeRequestReview?: ChangeRequestReview,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ChangeRequestsApiAxiosParamCreator(configuration).reviewChangeRequest(
        changeRequestReview,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ChangeRequestsApi - factory interface
 * @export
 */
export const ChangeRequestsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Apply all the change request into ERP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAllChangeRequest(options?: any) {
      return ChangeRequestsApiFp(configuration).applyAllChangeRequest(options)(axios, basePath);
    },
    /**
     *
     * @summary Apply the change request into ERP
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyChangeRequest(id: number, options?: any) {
      return ChangeRequestsApiFp(configuration).applyChangeRequest(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Apply multiple change request into ERP
     * @param {ChangeRequestIds} [changeRequestIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyMultipleChangeRequests(changeRequestIds?: ChangeRequestIds, options?: any) {
      return ChangeRequestsApiFp(configuration).applyMultipleChangeRequests(changeRequestIds, options)(axios, basePath);
    },
    /**
     *
     * @summary Creates a change request
     * @param {NewChangeRequest} [newChangeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createChangeRequest(newChangeRequest?: NewChangeRequest, options?: any) {
      return ChangeRequestsApiFp(configuration).createChangeRequest(newChangeRequest, options)(axios, basePath);
    },
    /**
     *
     * @summary Export all open change request and update its status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllChangeRequests(options?: any) {
      return ChangeRequestsApiFp(configuration).exportAllChangeRequests(options)(axios, basePath);
    },
    /**
     *
     * @summary Export a change request and update its status
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportChangeRequest(id: number, options?: any) {
      return ChangeRequestsApiFp(configuration).exportChangeRequest(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Export multiple request and update their status
     * @param {ChangeRequestIds} [changeRequestIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultipleChangeRequests(changeRequestIds?: ChangeRequestIds, options?: any) {
      return ChangeRequestsApiFp(configuration).exportMultipleChangeRequests(changeRequestIds, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Get all change requests
     * @param {number} [limit]
     * @param {number} [page]
     * @param {number} [businessEntityId]
     * @param {string} [userId]
     * @param {string} [type]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllChangeRequests(
      limit?: number,
      page?: number,
      businessEntityId?: number,
      userId?: string,
      type?: string,
      plant?: string,
      bomCheckId?: number,
      options?: any,
    ) {
      return ChangeRequestsApiFp(configuration).getAllChangeRequests(
        limit,
        page,
        businessEntityId,
        userId,
        type,
        plant,
        bomCheckId,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get a change request
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequest(id: number, options?: any) {
      return ChangeRequestsApiFp(configuration).getChangeRequest(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available authors of change requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequestAuthors(options?: any) {
      return ChangeRequestsApiFp(configuration).getChangeRequestAuthors(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available Business Entities of change requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRequestBusinessEntities(options?: any) {
      return ChangeRequestsApiFp(configuration).getChangeRequestBusinessEntities(options)(axios, basePath);
    },
    /**
     *
     * @summary Review a change request
     * @param {ChangeRequestReview} [changeRequestReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewChangeRequest(changeRequestReview?: ChangeRequestReview, options?: any) {
      return ChangeRequestsApiFp(configuration).reviewChangeRequest(changeRequestReview, options)(axios, basePath);
    },
  };
};

/**
 * ChangeRequestsApi - object-oriented interface
 * @export
 * @class ChangeRequestsApi
 * @extends {BaseAPI}
 */
export class ChangeRequestsApi extends BaseAPI {
  /**
   *
   * @summary Apply all the change request into ERP
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public applyAllChangeRequest(options?: any) {
    return ChangeRequestsApiFp(this.configuration).applyAllChangeRequest(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Apply the change request into ERP
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public applyChangeRequest(id: number, options?: any) {
    return ChangeRequestsApiFp(this.configuration).applyChangeRequest(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Apply multiple change request into ERP
   * @param {ChangeRequestIds} [changeRequestIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public applyMultipleChangeRequests(changeRequestIds?: ChangeRequestIds, options?: any) {
    return ChangeRequestsApiFp(this.configuration).applyMultipleChangeRequests(changeRequestIds, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Creates a change request
   * @param {NewChangeRequest} [newChangeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public createChangeRequest(newChangeRequest?: NewChangeRequest, options?: any) {
    return ChangeRequestsApiFp(this.configuration).createChangeRequest(newChangeRequest, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Export all open change request and update its status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public exportAllChangeRequests(options?: any) {
    return ChangeRequestsApiFp(this.configuration).exportAllChangeRequests(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export a change request and update its status
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public exportChangeRequest(id: number, options?: any) {
    return ChangeRequestsApiFp(this.configuration).exportChangeRequest(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export multiple request and update their status
   * @param {ChangeRequestIds} [changeRequestIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public exportMultipleChangeRequests(changeRequestIds?: ChangeRequestIds, options?: any) {
    return ChangeRequestsApiFp(this.configuration).exportMultipleChangeRequests(changeRequestIds, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all change requests
   * @param {number} [limit]
   * @param {number} [page]
   * @param {number} [businessEntityId]
   * @param {string} [userId]
   * @param {string} [type]
   * @param {string} [plant]
   * @param {number} [bomCheckId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public getAllChangeRequests(
    limit?: number,
    page?: number,
    businessEntityId?: number,
    userId?: string,
    type?: string,
    plant?: string,
    bomCheckId?: number,
    options?: any,
  ) {
    return ChangeRequestsApiFp(this.configuration).getAllChangeRequests(
      limit,
      page,
      businessEntityId,
      userId,
      type,
      plant,
      bomCheckId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a change request
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public getChangeRequest(id: number, options?: any) {
    return ChangeRequestsApiFp(this.configuration).getChangeRequest(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available authors of change requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public getChangeRequestAuthors(options?: any) {
    return ChangeRequestsApiFp(this.configuration).getChangeRequestAuthors(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available Business Entities of change requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public getChangeRequestBusinessEntities(options?: any) {
    return ChangeRequestsApiFp(this.configuration).getChangeRequestBusinessEntities(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Review a change request
   * @param {ChangeRequestReview} [changeRequestReview]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangeRequestsApi
   */
  public reviewChangeRequest(changeRequestReview?: ChangeRequestReview, options?: any) {
    return ChangeRequestsApiFp(this.configuration).reviewChangeRequest(changeRequestReview, options)(
      this.axios,
      this.basePath,
    );
  }
}

/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Setup a phone-call with SPARETECH
     * @param {PhoneContact} [phoneContact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setupPhoneCall(phoneContact?: PhoneContact, options: any = {}): RequestArgs {
      const localVarPath = `/contact/setup-call`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"PhoneContact" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(phoneContact !== undefined ? phoneContact : {})
        : phoneContact || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Setup a phone-call with SPARETECH
     * @param {PhoneContact} [phoneContact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setupPhoneCall(
      phoneContact?: PhoneContact,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ContactApiAxiosParamCreator(configuration).setupPhoneCall(phoneContact, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Setup a phone-call with SPARETECH
     * @param {PhoneContact} [phoneContact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setupPhoneCall(phoneContact?: PhoneContact, options?: any) {
      return ContactApiFp(configuration).setupPhoneCall(phoneContact, options)(axios, basePath);
    },
  };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
  /**
   *
   * @summary Setup a phone-call with SPARETECH
   * @param {PhoneContact} [phoneContact]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public setupPhoneCall(phoneContact?: PhoneContact, options?: any) {
    return ContactApiFp(this.configuration).setupPhoneCall(phoneContact, options)(this.axios, this.basePath);
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Checks if the server is running
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(options: any = {}): RequestArgs {
      const localVarPath = `/healthcheck`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Checks if the server is running
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).healthcheck(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Checks if the server is running
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(options?: any) {
      return DefaultApiFp(configuration).healthcheck(options)(axios, basePath);
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Checks if the server is running
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public healthcheck(options?: any) {
    return DefaultApiFp(this.configuration).healthcheck(options)(this.axios, this.basePath);
  }
}

/**
 * DumpApi - axios parameter creator
 * @export
 */
export const DumpApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Print all active (suspended or running) coroutines to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpCoroutines(options: any = {}): RequestArgs {
      const localVarPath = `/dump/coroutines`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Dump threads to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpThreads(options: any = {}): RequestArgs {
      const localVarPath = `/dump/threads`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DumpApi - functional programming interface
 * @export
 */
export const DumpApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Print all active (suspended or running) coroutines to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpCoroutines(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DumpApiAxiosParamCreator(configuration).dumpCoroutines(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Dump threads to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpThreads(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DumpApiAxiosParamCreator(configuration).dumpThreads(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DumpApi - factory interface
 * @export
 */
export const DumpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Print all active (suspended or running) coroutines to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpCoroutines(options?: any) {
      return DumpApiFp(configuration).dumpCoroutines(options)(axios, basePath);
    },
    /**
     *
     * @summary Dump threads to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpThreads(options?: any) {
      return DumpApiFp(configuration).dumpThreads(options)(axios, basePath);
    },
  };
};

/**
 * DumpApi - object-oriented interface
 * @export
 * @class DumpApi
 * @extends {BaseAPI}
 */
export class DumpApi extends BaseAPI {
  /**
   *
   * @summary Print all active (suspended or running) coroutines to the log
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DumpApi
   */
  public dumpCoroutines(options?: any) {
    return DumpApiFp(this.configuration).dumpCoroutines(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Dump threads to the log
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DumpApi
   */
  public dumpThreads(options?: any) {
    return DumpApiFp(this.configuration).dumpThreads(options)(this.axios, this.basePath);
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Delete an uploaded file
     * @param {string} id File UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteFile.");
      }
      const localVarPath = `/files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a downloadable file link
     * @param {string} id File UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileLink(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getFileLink.");
      }
      const localVarPath = `/files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload a File
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options: any = {}): RequestArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError("file", "Required parameter file was null or undefined when calling uploadFile.");
      }
      const localVarPath = `/files`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Delete an uploaded file
     * @param {string} id File UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).deleteFile(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get a downloadable file link
     * @param {string} id File UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileLink(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDownload> {
      const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).getFileLink(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Upload a File
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileReference> {
      const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).uploadFile(file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Delete an uploaded file
     * @param {string} id File UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(id: string, options?: any) {
      return FilesApiFp(configuration).deleteFile(id, options)(axios, basePath);
    },
    /**
     * Get a downloadable file link
     * @param {string} id File UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileLink(id: string, options?: any) {
      return FilesApiFp(configuration).getFileLink(id, options)(axios, basePath);
    },
    /**
     * Upload a File
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options?: any) {
      return FilesApiFp(configuration).uploadFile(file, options)(axios, basePath);
    },
  };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   * Delete an uploaded file
   * @param {string} id File UUID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public deleteFile(id: string, options?: any) {
    return FilesApiFp(this.configuration).deleteFile(id, options)(this.axios, this.basePath);
  }

  /**
   * Get a downloadable file link
   * @param {string} id File UUID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getFileLink(id: string, options?: any) {
    return FilesApiFp(this.configuration).getFileLink(id, options)(this.axios, this.basePath);
  }

  /**
   * Upload a File
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public uploadFile(file: any, options?: any) {
    return FilesApiFp(this.configuration).uploadFile(file, options)(this.axios, this.basePath);
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete a job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJob(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteJob.");
      }
      const localVarPath = `/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Job Endpoint
     * @summary get a job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getJob.");
      }
      const localVarPath = `/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a user\'s jobs
     * @param {string} state
     * @param {JobsOrder} [order]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserJobs(state: string, order?: JobsOrder, limit?: number, offset?: number, options: any = {}): RequestArgs {
      // verify required parameter 'state' is not null or undefined
      if (state === null || state === undefined) {
        throw new RequiredError("state", "Required parameter state was null or undefined when calling getUserJobs.");
      }
      const localVarPath = `/jobs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter["state"] = state;
      }

      if (order !== undefined) {
        localVarQueryParameter["order"] = order;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Job Endpoint
     * @summary update a job
     * @param {number} id
     * @param {JobLogData} [jobLogData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJob(id: number, jobLogData?: JobLogData, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateJob.");
      }
      const localVarPath = `/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"JobLogData" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(jobLogData !== undefined ? jobLogData : {})
        : jobLogData || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete a job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJob(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).deleteJob(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Job Endpoint
     * @summary get a job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).getJob(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a user\'s jobs
     * @param {string} state
     * @param {JobsOrder} [order]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserJobs(
      state: string,
      order?: JobsOrder,
      limit?: number,
      offset?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jobs> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).getUserJobs(
        state,
        order,
        limit,
        offset,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Job Endpoint
     * @summary update a job
     * @param {number} id
     * @param {JobLogData} [jobLogData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJob(
      id: number,
      jobLogData?: JobLogData,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).updateJob(id, jobLogData, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Delete a job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJob(id: number, options?: any) {
      return JobsApiFp(configuration).deleteJob(id, options)(axios, basePath);
    },
    /**
     * Job Endpoint
     * @summary get a job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(id: number, options?: any) {
      return JobsApiFp(configuration).getJob(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a user\'s jobs
     * @param {string} state
     * @param {JobsOrder} [order]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserJobs(state: string, order?: JobsOrder, limit?: number, offset?: number, options?: any) {
      return JobsApiFp(configuration).getUserJobs(state, order, limit, offset, options)(axios, basePath);
    },
    /**
     * Job Endpoint
     * @summary update a job
     * @param {number} id
     * @param {JobLogData} [jobLogData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateJob(id: number, jobLogData?: JobLogData, options?: any) {
      return JobsApiFp(configuration).updateJob(id, jobLogData, options)(axios, basePath);
    },
  };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @summary Delete a job
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public deleteJob(id: number, options?: any) {
    return JobsApiFp(this.configuration).deleteJob(id, options)(this.axios, this.basePath);
  }

  /**
   * Job Endpoint
   * @summary get a job
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJob(id: number, options?: any) {
    return JobsApiFp(this.configuration).getJob(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a user\'s jobs
   * @param {string} state
   * @param {JobsOrder} [order]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getUserJobs(state: string, order?: JobsOrder, limit?: number, offset?: number, options?: any) {
    return JobsApiFp(this.configuration).getUserJobs(state, order, limit, offset, options)(this.axios, this.basePath);
  }

  /**
   * Job Endpoint
   * @summary update a job
   * @param {number} id
   * @param {JobLogData} [jobLogData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public updateJob(id: number, jobLogData?: JobLogData, options?: any) {
    return JobsApiFp(this.configuration).updateJob(id, jobLogData, options)(this.axios, this.basePath);
  }
}

/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get available languages from business entity
     * @param {number} businessEntityId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableLanguages(businessEntityId: number, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling getAvailableLanguages.",
        );
      }
      const localVarPath = `/available-languages/{businessEntityId}`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get available languages from business entity
     * @param {number} businessEntityId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableLanguages(
      businessEntityId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).getAvailableLanguages(
        businessEntityId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get available languages from business entity
     * @param {number} businessEntityId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableLanguages(businessEntityId: number, options?: any) {
      return LanguageApiFp(configuration).getAvailableLanguages(businessEntityId, options)(axios, basePath);
    },
  };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
  /**
   *
   * @summary Get available languages from business entity
   * @param {number} businessEntityId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LanguageApi
   */
  public getAvailableLanguages(businessEntityId: number, options?: any) {
    return LanguageApiFp(this.configuration).getAvailableLanguages(businessEntityId, options)(
      this.axios,
      this.basePath,
    );
  }
}

/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a manufacturer by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getManufacturer.");
      }
      const localVarPath = `/manufacturers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a manufacturer by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDetails> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getManufacturer(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Get a manufacturer by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(id: string, options?: any) {
      return ManufacturersApiFp(configuration).getManufacturer(id, options)(axios, basePath);
    },
  };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
  /**
   *
   * @summary Get a manufacturer by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getManufacturer(id: string, options?: any) {
    return ManufacturersApiFp(this.configuration).getManufacturer(id, options)(this.axios, this.basePath);
  }
}

/**
 * MaterialExtensionsApi - axios parameter creator
 * @export
 */
export const MaterialExtensionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Creates a material extension
     * @param {NewMaterialExtension} [newMaterialExtension]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialExtension(newMaterialExtension?: NewMaterialExtension, options: any = {}): RequestArgs {
      const localVarPath = `/material-extensions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewMaterialExtension" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newMaterialExtension !== undefined ? newMaterialExtension : {})
        : newMaterialExtension || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deletes a material extension
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaterialExtension(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteMaterialExtension.",
        );
      }
      const localVarPath = `/material-extensions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export a material creation and update its status
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMaterialExtension(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling exportMaterialExtension.",
        );
      }
      const localVarPath = `/material-extensions/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export created material extensions by status and update their status
     * @param {string} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMaterialExtensionsByStatus(status: string, options: any = {}): RequestArgs {
      // verify required parameter 'status' is not null or undefined
      if (status === null || status === undefined) {
        throw new RequiredError(
          "status",
          "Required parameter status was null or undefined when calling exportMaterialExtensionsByStatus.",
        );
      }
      const localVarPath = `/material-extensions/export-by-status`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export multiple material extensions and update their status
     * @param {MaterialExtensionIds} [materialExtensionIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultipleMaterialExtension(materialExtensionIds?: MaterialExtensionIds, options: any = {}): RequestArgs {
      const localVarPath = `/material-extensions/export-multiple`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialExtensionIds" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialExtensionIds !== undefined ? materialExtensionIds : {})
        : materialExtensionIds || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all material extensions
     * @param {number} [limit]
     * @param {number} [page]
     * @param {number} [businessEntityId]
     * @param {string} [userId]
     * @param {string} [type]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMaterialExtensions(
      limit?: number,
      page?: number,
      businessEntityId?: number,
      userId?: string,
      type?: string,
      plant?: string,
      bomCheckId?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/material-extensions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (businessEntityId !== undefined) {
        localVarQueryParameter["businessEntityId"] = businessEntityId;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (plant !== undefined) {
        localVarQueryParameter["plant"] = plant;
      }

      if (bomCheckId !== undefined) {
        localVarQueryParameter["bomCheckId"] = bomCheckId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a material extension
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtension(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getMaterialExtension.");
      }
      const localVarPath = `/material-extensions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available authors of material extensions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionAuthors(options: any = {}): RequestArgs {
      const localVarPath = `/material-extensions/filters/authors`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available Business Entities of material extensions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionBusinessEntities(options: any = {}): RequestArgs {
      const localVarPath = `/material-extensions/filters/businessEntities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a material extension
     * @param {number} id
     * @param {UpdateMaterialExtension} [updateMaterialExtension]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialExtension(
      id: number,
      updateMaterialExtension?: UpdateMaterialExtension,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateMaterialExtension.",
        );
      }
      const localVarPath = `/material-extensions/{id}/update`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UpdateMaterialExtension" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateMaterialExtension !== undefined ? updateMaterialExtension : {})
        : updateMaterialExtension || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MaterialExtensionsApi - functional programming interface
 * @export
 */
export const MaterialExtensionsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Creates a material extension
     * @param {NewMaterialExtension} [newMaterialExtension]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialExtension(
      newMaterialExtension?: NewMaterialExtension,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).createMaterialExtension(
        newMaterialExtension,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Deletes a material extension
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaterialExtension(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).deleteMaterialExtension(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export a material creation and update its status
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMaterialExtension(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).exportMaterialExtension(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export created material extensions by status and update their status
     * @param {string} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMaterialExtensionsByStatus(
      status: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).exportMaterialExtensionsByStatus(
        status,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export multiple material extensions and update their status
     * @param {MaterialExtensionIds} [materialExtensionIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultipleMaterialExtension(
      materialExtensionIds?: MaterialExtensionIds,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).exportMultipleMaterialExtension(
        materialExtensionIds,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all material extensions
     * @param {number} [limit]
     * @param {number} [page]
     * @param {number} [businessEntityId]
     * @param {string} [userId]
     * @param {string} [type]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMaterialExtensions(
      limit?: number,
      page?: number,
      businessEntityId?: number,
      userId?: string,
      type?: string,
      plant?: string,
      bomCheckId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialExtensions> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).getAllMaterialExtensions(
        limit,
        page,
        businessEntityId,
        userId,
        type,
        plant,
        bomCheckId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a material extension
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtension(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialExtension> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).getMaterialExtension(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available authors of material extensions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionAuthors(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs =
        MaterialExtensionsApiAxiosParamCreator(configuration).getMaterialExtensionAuthors(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available Business Entities of material extensions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionBusinessEntities(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntities> {
      const localVarAxiosArgs =
        MaterialExtensionsApiAxiosParamCreator(configuration).getMaterialExtensionBusinessEntities(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updates a material extension
     * @param {number} id
     * @param {UpdateMaterialExtension} [updateMaterialExtension]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialExtension(
      id: number,
      updateMaterialExtension?: UpdateMaterialExtension,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialExtensionsApiAxiosParamCreator(configuration).updateMaterialExtension(
        id,
        updateMaterialExtension,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MaterialExtensionsApi - factory interface
 * @export
 */
export const MaterialExtensionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Creates a material extension
     * @param {NewMaterialExtension} [newMaterialExtension]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialExtension(newMaterialExtension?: NewMaterialExtension, options?: any) {
      return MaterialExtensionsApiFp(configuration).createMaterialExtension(newMaterialExtension, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Deletes a material extension
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaterialExtension(id: number, options?: any) {
      return MaterialExtensionsApiFp(configuration).deleteMaterialExtension(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Export a material creation and update its status
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMaterialExtension(id: number, options?: any) {
      return MaterialExtensionsApiFp(configuration).exportMaterialExtension(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Export created material extensions by status and update their status
     * @param {string} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMaterialExtensionsByStatus(status: string, options?: any) {
      return MaterialExtensionsApiFp(configuration).exportMaterialExtensionsByStatus(status, options)(axios, basePath);
    },
    /**
     *
     * @summary Export multiple material extensions and update their status
     * @param {MaterialExtensionIds} [materialExtensionIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultipleMaterialExtension(materialExtensionIds?: MaterialExtensionIds, options?: any) {
      return MaterialExtensionsApiFp(configuration).exportMultipleMaterialExtension(materialExtensionIds, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Get all material extensions
     * @param {number} [limit]
     * @param {number} [page]
     * @param {number} [businessEntityId]
     * @param {string} [userId]
     * @param {string} [type]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMaterialExtensions(
      limit?: number,
      page?: number,
      businessEntityId?: number,
      userId?: string,
      type?: string,
      plant?: string,
      bomCheckId?: number,
      options?: any,
    ) {
      return MaterialExtensionsApiFp(configuration).getAllMaterialExtensions(
        limit,
        page,
        businessEntityId,
        userId,
        type,
        plant,
        bomCheckId,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get a material extension
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtension(id: number, options?: any) {
      return MaterialExtensionsApiFp(configuration).getMaterialExtension(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available authors of material extensions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionAuthors(options?: any) {
      return MaterialExtensionsApiFp(configuration).getMaterialExtensionAuthors(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available Business Entities of material extensions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionBusinessEntities(options?: any) {
      return MaterialExtensionsApiFp(configuration).getMaterialExtensionBusinessEntities(options)(axios, basePath);
    },
    /**
     *
     * @summary Updates a material extension
     * @param {number} id
     * @param {UpdateMaterialExtension} [updateMaterialExtension]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialExtension(id: number, updateMaterialExtension?: UpdateMaterialExtension, options?: any) {
      return MaterialExtensionsApiFp(configuration).updateMaterialExtension(
        id,
        updateMaterialExtension,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * MaterialExtensionsApi - object-oriented interface
 * @export
 * @class MaterialExtensionsApi
 * @extends {BaseAPI}
 */
export class MaterialExtensionsApi extends BaseAPI {
  /**
   *
   * @summary Creates a material extension
   * @param {NewMaterialExtension} [newMaterialExtension]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public createMaterialExtension(newMaterialExtension?: NewMaterialExtension, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).createMaterialExtension(newMaterialExtension, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Deletes a material extension
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public deleteMaterialExtension(id: number, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).deleteMaterialExtension(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export a material creation and update its status
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public exportMaterialExtension(id: number, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).exportMaterialExtension(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export created material extensions by status and update their status
   * @param {string} status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public exportMaterialExtensionsByStatus(status: string, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).exportMaterialExtensionsByStatus(status, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Export multiple material extensions and update their status
   * @param {MaterialExtensionIds} [materialExtensionIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public exportMultipleMaterialExtension(materialExtensionIds?: MaterialExtensionIds, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).exportMultipleMaterialExtension(materialExtensionIds, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all material extensions
   * @param {number} [limit]
   * @param {number} [page]
   * @param {number} [businessEntityId]
   * @param {string} [userId]
   * @param {string} [type]
   * @param {string} [plant]
   * @param {number} [bomCheckId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public getAllMaterialExtensions(
    limit?: number,
    page?: number,
    businessEntityId?: number,
    userId?: string,
    type?: string,
    plant?: string,
    bomCheckId?: number,
    options?: any,
  ) {
    return MaterialExtensionsApiFp(this.configuration).getAllMaterialExtensions(
      limit,
      page,
      businessEntityId,
      userId,
      type,
      plant,
      bomCheckId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a material extension
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public getMaterialExtension(id: number, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).getMaterialExtension(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available authors of material extensions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public getMaterialExtensionAuthors(options?: any) {
    return MaterialExtensionsApiFp(this.configuration).getMaterialExtensionAuthors(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available Business Entities of material extensions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public getMaterialExtensionBusinessEntities(options?: any) {
    return MaterialExtensionsApiFp(this.configuration).getMaterialExtensionBusinessEntities(options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Updates a material extension
   * @param {number} id
   * @param {UpdateMaterialExtension} [updateMaterialExtension]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialExtensionsApi
   */
  public updateMaterialExtension(id: number, updateMaterialExtension?: UpdateMaterialExtension, options?: any) {
    return MaterialExtensionsApiFp(this.configuration).updateMaterialExtension(
      id,
      updateMaterialExtension,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * MaterialMasterApi - axios parameter creator
 * @export
 */
export const MaterialMasterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get cleansing suggestions
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCleansingSuggestions(requestBody: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          "requestBody",
          "Required parameter requestBody was null or undefined when calling getCleansingSuggestions.",
        );
      }
      const localVarPath = `/material-master/cleansing-suggestions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Match identifying fields against material master columns
     * @param {string} [manufacturer]
     * @param {string} [articleNumber]
     * @param {string} [typeCode]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicatesFromMaterialMaster(
      manufacturer?: string,
      articleNumber?: string,
      typeCode?: string,
      limit?: number,
      page?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/material-master/duplicate-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (articleNumber !== undefined) {
        localVarQueryParameter["articleNumber"] = articleNumber;
      }

      if (typeCode !== undefined) {
        localVarQueryParameter["typeCode"] = typeCode;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mapping for a material master
     * @param {string} type Type of Mapping (SPT-Result, Export, Database)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormMapping(type: string, options: any = {}): RequestArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError("type", "Required parameter type was null or undefined when calling getFormMapping.");
      }
      const localVarPath = `/material-masters/mapping/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(type)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get master data creation form
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterDataForm(selectedPlant?: string, options: any = {}): RequestArgs {
      const localVarPath = `/material-master/form`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (selectedPlant !== undefined) {
        localVarQueryParameter["selectedPlant"] = selectedPlant;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get initial Values for master data creation form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterDataFormInitialValues(options: any = {}): RequestArgs {
      const localVarPath = `/material-master/form/initialValues`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a form adapted to material master-form field mappings
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialEditForm(selectedPlant?: string, options: any = {}): RequestArgs {
      const localVarPath = `/material-master/edit-form`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (selectedPlant !== undefined) {
        localVarQueryParameter["selectedPlant"] = selectedPlant;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a form adapted to material extension
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionForm(selectedPlant?: string, options: any = {}): RequestArgs {
      const localVarPath = `/material-master/extension`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (selectedPlant !== undefined) {
        localVarQueryParameter["selectedPlant"] = selectedPlant;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Read material master from a User\'s business entity
     * @param {MaterialMasterFilters} materialMasterFilters
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {string} [filterBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMaster(
      materialMasterFilters: MaterialMasterFilters,
      page?: number,
      numberOfRows?: number,
      filterBy?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterFilters' is not null or undefined
      if (materialMasterFilters === null || materialMasterFilters === undefined) {
        throw new RequiredError(
          "materialMasterFilters",
          "Required parameter materialMasterFilters was null or undefined when calling getMaterialMaster.",
        );
      }
      const localVarPath = `/material-master`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberOfRows !== undefined) {
        localVarQueryParameter["numberOfRows"] = numberOfRows;
      }

      if (filterBy !== undefined) {
        localVarQueryParameter["filterBy"] = filterBy;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialMasterFilters" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialMasterFilters !== undefined ? materialMasterFilters : {})
        : materialMasterFilters || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get material master logs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterHistory(options: any = {}): RequestArgs {
      const localVarPath = `/material-master/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get filters for display columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterSearchFilters(options: any = {}): RequestArgs {
      const localVarPath = `/material-master/search/filters`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get material master statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterStatistics(options: any = {}): RequestArgs {
      const localVarPath = `/material-master/statistics`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all plant values from the dropdown
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlantValues(options: any = {}): RequestArgs {
      const localVarPath = `/material-master/form/plant-values`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get header that are selectable for the cleansing suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSelectableCleansingHeaders(options: any = {}): RequestArgs {
      const localVarPath = `/material-master/cleansing-suggestions/selectable-headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user form values
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFormValues(userId: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUserFormValues.",
        );
      }
      const localVarPath = `/material-master/user-form-values/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search within all rows from a material master
     * @param {string} term
     * @param {MaterialMasterFilters} materialMasterFilters
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {string} [filterBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMaterialMaster(
      term: string,
      materialMasterFilters: MaterialMasterFilters,
      page?: number,
      numberOfRows?: number,
      filterBy?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'term' is not null or undefined
      if (term === null || term === undefined) {
        throw new RequiredError(
          "term",
          "Required parameter term was null or undefined when calling searchMaterialMaster.",
        );
      }
      // verify required parameter 'materialMasterFilters' is not null or undefined
      if (materialMasterFilters === null || materialMasterFilters === undefined) {
        throw new RequiredError(
          "materialMasterFilters",
          "Required parameter materialMasterFilters was null or undefined when calling searchMaterialMaster.",
        );
      }
      const localVarPath = `/material-master/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (term !== undefined) {
        localVarQueryParameter["term"] = term;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberOfRows !== undefined) {
        localVarQueryParameter["numberOfRows"] = numberOfRows;
      }

      if (filterBy !== undefined) {
        localVarQueryParameter["filterBy"] = filterBy;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialMasterFilters" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialMasterFilters !== undefined ? materialMasterFilters : {})
        : materialMasterFilters || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search for duplicates within all rows from a material master
     * @param {string} term
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMaterialMasterDuplicates(term: string, page?: number, numberOfRows?: number, options: any = {}): RequestArgs {
      // verify required parameter 'term' is not null or undefined
      if (term === null || term === undefined) {
        throw new RequiredError(
          "term",
          "Required parameter term was null or undefined when calling searchMaterialMasterDuplicates.",
        );
      }
      const localVarPath = `/material-master/duplicate-search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (term !== undefined) {
        localVarQueryParameter["term"] = term;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberOfRows !== undefined) {
        localVarQueryParameter["numberOfRows"] = numberOfRows;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user values
     * @param {string} userId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormValues(userId: string, body?: object, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling updateUserFormValues.",
        );
      }
      const localVarPath = `/material-master/user-form-values/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"object" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload new Version of Material Master
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMaterialMaster(file: string, options: any = {}): RequestArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling uploadMaterialMaster.",
        );
      }
      const localVarPath = `/material-master/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MaterialMasterApi - functional programming interface
 * @export
 */
export const MaterialMasterApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get cleansing suggestions
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCleansingSuggestions(
      requestBody: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuickView> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getCleansingSuggestions(
        requestBody,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Match identifying fields against material master columns
     * @param {string} [manufacturer]
     * @param {string} [articleNumber]
     * @param {string} [typeCode]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicatesFromMaterialMaster(
      manufacturer?: string,
      articleNumber?: string,
      typeCode?: string,
      limit?: number,
      page?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterSearchResults> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getDuplicatesFromMaterialMaster(
        manufacturer,
        articleNumber,
        typeCode,
        limit,
        page,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get mapping for a material master
     * @param {string} type Type of Mapping (SPT-Result, Export, Database)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormMapping(
      type: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormFieldMapping> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getFormMapping(type, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get master data creation form
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterDataForm(
      selectedPlant?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterForm> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMasterDataForm(
        selectedPlant,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get initial Values for master data creation form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterDataFormInitialValues(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs =
        MaterialMasterApiAxiosParamCreator(configuration).getMasterDataFormInitialValues(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a form adapted to material master-form field mappings
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialEditForm(
      selectedPlant?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterForm> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialEditForm(
        selectedPlant,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a form adapted to material extension
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionForm(
      selectedPlant?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterForm> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialExtensionForm(
        selectedPlant,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Read material master from a User\'s business entity
     * @param {MaterialMasterFilters} materialMasterFilters
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {string} [filterBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMaster(
      materialMasterFilters: MaterialMasterFilters,
      page?: number,
      numberOfRows?: number,
      filterBy?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMaster> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMaster(
        materialMasterFilters,
        page,
        numberOfRows,
        filterBy,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get material master logs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterHistory(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterLogs> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterHistory(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get filters for display columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterSearchFilters(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialMasterSearchFilter>> {
      const localVarAxiosArgs =
        MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterSearchFilters(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get material master statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterStatistics(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialMasterStatistics>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterStatistics(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all plant values from the dropdown
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlantValues(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabelValuePair>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getPlantValues(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get header that are selectable for the cleansing suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSelectableCleansingHeaders(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableHeader> {
      const localVarAxiosArgs =
        MaterialMasterApiAxiosParamCreator(configuration).getSelectableCleansingHeaders(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user form values
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFormValues(
      userId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getUserFormValues(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Search within all rows from a material master
     * @param {string} term
     * @param {MaterialMasterFilters} materialMasterFilters
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {string} [filterBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMaterialMaster(
      term: string,
      materialMasterFilters: MaterialMasterFilters,
      page?: number,
      numberOfRows?: number,
      filterBy?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterSearchResults> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).searchMaterialMaster(
        term,
        materialMasterFilters,
        page,
        numberOfRows,
        filterBy,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Search for duplicates within all rows from a material master
     * @param {string} term
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMaterialMasterDuplicates(
      term: string,
      page?: number,
      numberOfRows?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterSearchResults> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).searchMaterialMasterDuplicates(
        term,
        page,
        numberOfRows,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update user values
     * @param {string} userId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormValues(
      userId: string,
      body?: object,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateUserFormValues(
        userId,
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Upload new Version of Material Master
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMaterialMaster(
      file: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).uploadMaterialMaster(file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MaterialMasterApi - factory interface
 * @export
 */
export const MaterialMasterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Get cleansing suggestions
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCleansingSuggestions(requestBody: Array<string>, options?: any) {
      return MaterialMasterApiFp(configuration).getCleansingSuggestions(requestBody, options)(axios, basePath);
    },
    /**
     *
     * @summary Match identifying fields against material master columns
     * @param {string} [manufacturer]
     * @param {string} [articleNumber]
     * @param {string} [typeCode]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicatesFromMaterialMaster(
      manufacturer?: string,
      articleNumber?: string,
      typeCode?: string,
      limit?: number,
      page?: number,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).getDuplicatesFromMaterialMaster(
        manufacturer,
        articleNumber,
        typeCode,
        limit,
        page,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get mapping for a material master
     * @param {string} type Type of Mapping (SPT-Result, Export, Database)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormMapping(type: string, options?: any) {
      return MaterialMasterApiFp(configuration).getFormMapping(type, options)(axios, basePath);
    },
    /**
     *
     * @summary Get master data creation form
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterDataForm(selectedPlant?: string, options?: any) {
      return MaterialMasterApiFp(configuration).getMasterDataForm(selectedPlant, options)(axios, basePath);
    },
    /**
     *
     * @summary Get initial Values for master data creation form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterDataFormInitialValues(options?: any) {
      return MaterialMasterApiFp(configuration).getMasterDataFormInitialValues(options)(axios, basePath);
    },
    /**
     *
     * @summary Get a form adapted to material master-form field mappings
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialEditForm(selectedPlant?: string, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialEditForm(selectedPlant, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a form adapted to material extension
     * @param {string} [selectedPlant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialExtensionForm(selectedPlant?: string, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialExtensionForm(selectedPlant, options)(axios, basePath);
    },
    /**
     *
     * @summary Read material master from a User\'s business entity
     * @param {MaterialMasterFilters} materialMasterFilters
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {string} [filterBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMaster(
      materialMasterFilters: MaterialMasterFilters,
      page?: number,
      numberOfRows?: number,
      filterBy?: string,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).getMaterialMaster(
        materialMasterFilters,
        page,
        numberOfRows,
        filterBy,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get material master logs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterHistory(options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterHistory(options)(axios, basePath);
    },
    /**
     *
     * @summary Get filters for display columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterSearchFilters(options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterSearchFilters(options)(axios, basePath);
    },
    /**
     *
     * @summary Get material master statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterStatistics(options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterStatistics(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all plant values from the dropdown
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlantValues(options?: any) {
      return MaterialMasterApiFp(configuration).getPlantValues(options)(axios, basePath);
    },
    /**
     *
     * @summary Get header that are selectable for the cleansing suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSelectableCleansingHeaders(options?: any) {
      return MaterialMasterApiFp(configuration).getSelectableCleansingHeaders(options)(axios, basePath);
    },
    /**
     *
     * @summary Get user form values
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFormValues(userId: string, options?: any) {
      return MaterialMasterApiFp(configuration).getUserFormValues(userId, options)(axios, basePath);
    },
    /**
     *
     * @summary Search within all rows from a material master
     * @param {string} term
     * @param {MaterialMasterFilters} materialMasterFilters
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {string} [filterBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMaterialMaster(
      term: string,
      materialMasterFilters: MaterialMasterFilters,
      page?: number,
      numberOfRows?: number,
      filterBy?: string,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).searchMaterialMaster(
        term,
        materialMasterFilters,
        page,
        numberOfRows,
        filterBy,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Search for duplicates within all rows from a material master
     * @param {string} term
     * @param {number} [page]
     * @param {number} [numberOfRows]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMaterialMasterDuplicates(term: string, page?: number, numberOfRows?: number, options?: any) {
      return MaterialMasterApiFp(configuration).searchMaterialMasterDuplicates(
        term,
        page,
        numberOfRows,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update user values
     * @param {string} userId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormValues(userId: string, body?: object, options?: any) {
      return MaterialMasterApiFp(configuration).updateUserFormValues(userId, body, options)(axios, basePath);
    },
    /**
     * Upload new Version of Material Master
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMaterialMaster(file: string, options?: any) {
      return MaterialMasterApiFp(configuration).uploadMaterialMaster(file, options)(axios, basePath);
    },
  };
};

/**
 * MaterialMasterApi - object-oriented interface
 * @export
 * @class MaterialMasterApi
 * @extends {BaseAPI}
 */
export class MaterialMasterApi extends BaseAPI {
  /**
   *
   * @summary Get cleansing suggestions
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getCleansingSuggestions(requestBody: Array<string>, options?: any) {
    return MaterialMasterApiFp(this.configuration).getCleansingSuggestions(requestBody, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Match identifying fields against material master columns
   * @param {string} [manufacturer]
   * @param {string} [articleNumber]
   * @param {string} [typeCode]
   * @param {number} [limit]
   * @param {number} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getDuplicatesFromMaterialMaster(
    manufacturer?: string,
    articleNumber?: string,
    typeCode?: string,
    limit?: number,
    page?: number,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).getDuplicatesFromMaterialMaster(
      manufacturer,
      articleNumber,
      typeCode,
      limit,
      page,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get mapping for a material master
   * @param {string} type Type of Mapping (SPT-Result, Export, Database)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getFormMapping(type: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getFormMapping(type, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get master data creation form
   * @param {string} [selectedPlant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMasterDataForm(selectedPlant?: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMasterDataForm(selectedPlant, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get initial Values for master data creation form
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMasterDataFormInitialValues(options?: any) {
    return MaterialMasterApiFp(this.configuration).getMasterDataFormInitialValues(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a form adapted to material master-form field mappings
   * @param {string} [selectedPlant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialEditForm(selectedPlant?: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialEditForm(selectedPlant, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get a form adapted to material extension
   * @param {string} [selectedPlant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialExtensionForm(selectedPlant?: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialExtensionForm(selectedPlant, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Read material master from a User\'s business entity
   * @param {MaterialMasterFilters} materialMasterFilters
   * @param {number} [page]
   * @param {number} [numberOfRows]
   * @param {string} [filterBy]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMaster(
    materialMasterFilters: MaterialMasterFilters,
    page?: number,
    numberOfRows?: number,
    filterBy?: string,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).getMaterialMaster(
      materialMasterFilters,
      page,
      numberOfRows,
      filterBy,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get material master logs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterHistory(options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterHistory(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get filters for display columns
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterSearchFilters(options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterSearchFilters(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get material master statistics
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterStatistics(options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterStatistics(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all plant values from the dropdown
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getPlantValues(options?: any) {
    return MaterialMasterApiFp(this.configuration).getPlantValues(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get header that are selectable for the cleansing suggestions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getSelectableCleansingHeaders(options?: any) {
    return MaterialMasterApiFp(this.configuration).getSelectableCleansingHeaders(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get user form values
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getUserFormValues(userId: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getUserFormValues(userId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Search within all rows from a material master
   * @param {string} term
   * @param {MaterialMasterFilters} materialMasterFilters
   * @param {number} [page]
   * @param {number} [numberOfRows]
   * @param {string} [filterBy]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public searchMaterialMaster(
    term: string,
    materialMasterFilters: MaterialMasterFilters,
    page?: number,
    numberOfRows?: number,
    filterBy?: string,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).searchMaterialMaster(
      term,
      materialMasterFilters,
      page,
      numberOfRows,
      filterBy,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Search for duplicates within all rows from a material master
   * @param {string} term
   * @param {number} [page]
   * @param {number} [numberOfRows]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public searchMaterialMasterDuplicates(term: string, page?: number, numberOfRows?: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).searchMaterialMasterDuplicates(
      term,
      page,
      numberOfRows,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update user values
   * @param {string} userId
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateUserFormValues(userId: string, body?: object, options?: any) {
    return MaterialMasterApiFp(this.configuration).updateUserFormValues(
      userId,
      body,
      options,
    )(this.axios, this.basePath);
  }

  /**
   * Upload new Version of Material Master
   * @param {string} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public uploadMaterialMaster(file: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).uploadMaterialMaster(file, options)(this.axios, this.basePath);
  }
}

/**
 * MaterialsApi - axios parameter creator
 * @export
 */
export const MaterialsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets all material values which are mapped to a form field ID
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterial(reference: string, options: any = {}): RequestArgs {
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getMappedMaterial.",
        );
      }
      const localVarPath = `/material/{reference}/mapped`.replace(
        `{${"reference"}}`,
        encodeURIComponent(String(reference)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the mapped materials with duplicate sparetech Id
     * @param {string} reference Material Reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterialsWithDuplicates(reference: string, options: any = {}): RequestArgs {
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getMappedMaterialsWithDuplicates.",
        );
      }
      const localVarPath = `/mapped/materials/{reference}/duplicate-sparetech-id`.replace(
        `{${"reference"}}`,
        encodeURIComponent(String(reference)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all cells from a material by reference
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterial(reference: string, options: any = {}): RequestArgs {
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getMaterial.",
        );
      }
      const localVarPath = `/material/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the events for a material
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialEvents(reference: string, options: any = {}): RequestArgs {
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getMaterialEvents.",
        );
      }
      const localVarPath = `/material/{reference}/events`.replace(
        `{${"reference"}}`,
        encodeURIComponent(String(reference)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product suggestions for a material
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSuggestions(reference: string, options: any = {}): RequestArgs {
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getProductSuggestions.",
        );
      }
      const localVarPath = `/material/{reference}/suggestions`.replace(
        `{${"reference"}}`,
        encodeURIComponent(String(reference)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MaterialsApi - functional programming interface
 * @export
 */
export const MaterialsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets all material values which are mapped to a form field ID
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterial(
      reference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappedMaterial> {
      const localVarAxiosArgs = MaterialsApiAxiosParamCreator(configuration).getMappedMaterial(reference, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the mapped materials with duplicate sparetech Id
     * @param {string} reference Material Reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterialsWithDuplicates(
      reference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<MappedMaterialsWithDuplicates>>> {
      const localVarAxiosArgs = MaterialsApiAxiosParamCreator(configuration).getMappedMaterialsWithDuplicates(
        reference,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all cells from a material by reference
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterial(
      reference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialDetails> {
      const localVarAxiosArgs = MaterialsApiAxiosParamCreator(configuration).getMaterial(reference, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all the events for a material
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialEvents(
      reference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialEvent>> {
      const localVarAxiosArgs = MaterialsApiAxiosParamCreator(configuration).getMaterialEvents(reference, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get product suggestions for a material
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSuggestions(
      reference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialProductSuggestion>> {
      const localVarAxiosArgs = MaterialsApiAxiosParamCreator(configuration).getProductSuggestions(reference, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MaterialsApi - factory interface
 * @export
 */
export const MaterialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Gets all material values which are mapped to a form field ID
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterial(reference: string, options?: any) {
      return MaterialsApiFp(configuration).getMappedMaterial(reference, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the mapped materials with duplicate sparetech Id
     * @param {string} reference Material Reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterialsWithDuplicates(reference: string, options?: any) {
      return MaterialsApiFp(configuration).getMappedMaterialsWithDuplicates(reference, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all cells from a material by reference
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterial(reference: string, options?: any) {
      return MaterialsApiFp(configuration).getMaterial(reference, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all the events for a material
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialEvents(reference: string, options?: any) {
      return MaterialsApiFp(configuration).getMaterialEvents(reference, options)(axios, basePath);
    },
    /**
     *
     * @summary Get product suggestions for a material
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductSuggestions(reference: string, options?: any) {
      return MaterialsApiFp(configuration).getProductSuggestions(reference, options)(axios, basePath);
    },
  };
};

/**
 * MaterialsApi - object-oriented interface
 * @export
 * @class MaterialsApi
 * @extends {BaseAPI}
 */
export class MaterialsApi extends BaseAPI {
  /**
   *
   * @summary Gets all material values which are mapped to a form field ID
   * @param {string} reference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialsApi
   */
  public getMappedMaterial(reference: string, options?: any) {
    return MaterialsApiFp(this.configuration).getMappedMaterial(reference, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the mapped materials with duplicate sparetech Id
   * @param {string} reference Material Reference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialsApi
   */
  public getMappedMaterialsWithDuplicates(reference: string, options?: any) {
    return MaterialsApiFp(this.configuration).getMappedMaterialsWithDuplicates(reference, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all cells from a material by reference
   * @param {string} reference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialsApi
   */
  public getMaterial(reference: string, options?: any) {
    return MaterialsApiFp(this.configuration).getMaterial(reference, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all the events for a material
   * @param {string} reference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialsApi
   */
  public getMaterialEvents(reference: string, options?: any) {
    return MaterialsApiFp(this.configuration).getMaterialEvents(reference, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get product suggestions for a material
   * @param {string} reference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialsApi
   */
  public getProductSuggestions(reference: string, options?: any) {
    return MaterialsApiFp(this.configuration).getProductSuggestions(reference, options)(this.axios, this.basePath);
  }
}

/**
 * PendingMaterialsApi - axios parameter creator
 * @export
 */
export const PendingMaterialsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Post a new pending material
     * @param {NewPendingMaterial} [newPendingMaterial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPendingMaterial(newPendingMaterial?: NewPendingMaterial, options: any = {}): RequestArgs {
      const localVarPath = `/pending-materials`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewPendingMaterial" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newPendingMaterial !== undefined ? newPendingMaterial : {})
        : newPendingMaterial || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a pending material
     * @param {number} id material Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePendingMaterial(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deletePendingMaterial.",
        );
      }
      const localVarPath = `/pending-materials/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export selected pending materials
     * @param {string} [stage]
     * @param {number} [jobId]
     * @param {PendingMaterialIds} [pendingMaterialIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultiplePendingMaterials(
      stage?: string,
      jobId?: number,
      pendingMaterialIds?: PendingMaterialIds,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/pending-materials/export-multiple`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (stage !== undefined) {
        localVarQueryParameter["stage"] = stage;
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"PendingMaterialIds" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(pendingMaterialIds !== undefined ? pendingMaterialIds : {})
        : pendingMaterialIds || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all pending materials
     * @param {string} stage
     * @param {number} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPendingMaterials(stage: string, jobId?: number, options: any = {}): RequestArgs {
      // verify required parameter 'stage' is not null or undefined
      if (stage === null || stage === undefined) {
        throw new RequiredError(
          "stage",
          "Required parameter stage was null or undefined when calling exportPendingMaterials.",
        );
      }
      const localVarPath = `/pending-materials/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (stage !== undefined) {
        localVarQueryParameter["stage"] = stage;
      }

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export a single pending materials
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportSinglePendingMaterial(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling exportSinglePendingMaterial.",
        );
      }
      const localVarPath = `/pending-materials/export/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a pending material
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterial(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getPendingMaterial.");
      }
      const localVarPath = `/pending-materials/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available authors of pendingMaterials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterialsAuthors(options: any = {}): RequestArgs {
      const localVarPath = `/pending-materials/filters/authors`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available Business Entities of pendingMaterials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterialsBusinessEntities(options: any = {}): RequestArgs {
      const localVarPath = `/pending-materials/filters/businessEntities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Performs a full text search across all materials linked to a material master
     * @param {string} [query]
     * @param {number} [numberOfRows]
     * @param {number} [page]
     * @param {string} [stage]
     * @param {string} [authorId]
     * @param {number} [businessEntityId]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {string} [status]
     * @param {number} [excludeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPendingMaterials(
      query?: string,
      numberOfRows?: number,
      page?: number,
      stage?: string,
      authorId?: string,
      businessEntityId?: number,
      plant?: string,
      bomCheckId?: number,
      status?: string,
      excludeId?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/pending-materials`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      if (numberOfRows !== undefined) {
        localVarQueryParameter["numberOfRows"] = numberOfRows;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (stage !== undefined) {
        localVarQueryParameter["stage"] = stage;
      }

      if (authorId !== undefined) {
        localVarQueryParameter["authorId"] = authorId;
      }

      if (businessEntityId !== undefined) {
        localVarQueryParameter["businessEntityId"] = businessEntityId;
      }

      if (plant !== undefined) {
        localVarQueryParameter["plant"] = plant;
      }

      if (bomCheckId !== undefined) {
        localVarQueryParameter["bomCheckId"] = bomCheckId;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (excludeId !== undefined) {
        localVarQueryParameter["excludeId"] = excludeId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post a pending material to update
     * @param {UpdatePendingMaterial} [updatePendingMaterial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePendingMaterial(updatePendingMaterial?: UpdatePendingMaterial, options: any = {}): RequestArgs {
      const localVarPath = `/pending-materials/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UpdatePendingMaterial" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updatePendingMaterial !== undefined ? updatePendingMaterial : {})
        : updatePendingMaterial || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PendingMaterialsApi - functional programming interface
 * @export
 */
export const PendingMaterialsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Post a new pending material
     * @param {NewPendingMaterial} [newPendingMaterial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPendingMaterial(
      newPendingMaterial?: NewPendingMaterial,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingMaterial> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).createPendingMaterial(
        newPendingMaterial,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete a pending material
     * @param {number} id material Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePendingMaterial(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).deletePendingMaterial(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export selected pending materials
     * @param {string} [stage]
     * @param {number} [jobId]
     * @param {PendingMaterialIds} [pendingMaterialIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultiplePendingMaterials(
      stage?: string,
      jobId?: number,
      pendingMaterialIds?: PendingMaterialIds,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).exportMultiplePendingMaterials(
        stage,
        jobId,
        pendingMaterialIds,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export all pending materials
     * @param {string} stage
     * @param {number} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPendingMaterials(
      stage: string,
      jobId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).exportPendingMaterials(
        stage,
        jobId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export a single pending materials
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportSinglePendingMaterial(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).exportSinglePendingMaterial(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a pending material
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterial(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingMaterial> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).getPendingMaterial(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available authors of pendingMaterials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterialsAuthors(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).getPendingMaterialsAuthors(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available Business Entities of pendingMaterials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterialsBusinessEntities(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntities> {
      const localVarAxiosArgs =
        PendingMaterialsApiAxiosParamCreator(configuration).getPendingMaterialsBusinessEntities(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Performs a full text search across all materials linked to a material master
     * @param {string} [query]
     * @param {number} [numberOfRows]
     * @param {number} [page]
     * @param {string} [stage]
     * @param {string} [authorId]
     * @param {number} [businessEntityId]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {string} [status]
     * @param {number} [excludeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPendingMaterials(
      query?: string,
      numberOfRows?: number,
      page?: number,
      stage?: string,
      authorId?: string,
      businessEntityId?: number,
      plant?: string,
      bomCheckId?: number,
      status?: string,
      excludeId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingMaterials> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).searchPendingMaterials(
        query,
        numberOfRows,
        page,
        stage,
        authorId,
        businessEntityId,
        plant,
        bomCheckId,
        status,
        excludeId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Post a pending material to update
     * @param {UpdatePendingMaterial} [updatePendingMaterial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePendingMaterial(
      updatePendingMaterial?: UpdatePendingMaterial,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PendingMaterialsApiAxiosParamCreator(configuration).updatePendingMaterial(
        updatePendingMaterial,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PendingMaterialsApi - factory interface
 * @export
 */
export const PendingMaterialsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Post a new pending material
     * @param {NewPendingMaterial} [newPendingMaterial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPendingMaterial(newPendingMaterial?: NewPendingMaterial, options?: any) {
      return PendingMaterialsApiFp(configuration).createPendingMaterial(newPendingMaterial, options)(axios, basePath);
    },
    /**
     * Delete a pending material
     * @param {number} id material Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePendingMaterial(id: number, options?: any) {
      return PendingMaterialsApiFp(configuration).deletePendingMaterial(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Export selected pending materials
     * @param {string} [stage]
     * @param {number} [jobId]
     * @param {PendingMaterialIds} [pendingMaterialIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportMultiplePendingMaterials(
      stage?: string,
      jobId?: number,
      pendingMaterialIds?: PendingMaterialIds,
      options?: any,
    ) {
      return PendingMaterialsApiFp(configuration).exportMultiplePendingMaterials(
        stage,
        jobId,
        pendingMaterialIds,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Export all pending materials
     * @param {string} stage
     * @param {number} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPendingMaterials(stage: string, jobId?: number, options?: any) {
      return PendingMaterialsApiFp(configuration).exportPendingMaterials(stage, jobId, options)(axios, basePath);
    },
    /**
     *
     * @summary Export a single pending materials
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportSinglePendingMaterial(id: number, options?: any) {
      return PendingMaterialsApiFp(configuration).exportSinglePendingMaterial(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a pending material
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterial(id: number, options?: any) {
      return PendingMaterialsApiFp(configuration).getPendingMaterial(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available authors of pendingMaterials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterialsAuthors(options?: any) {
      return PendingMaterialsApiFp(configuration).getPendingMaterialsAuthors(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all available Business Entities of pendingMaterials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPendingMaterialsBusinessEntities(options?: any) {
      return PendingMaterialsApiFp(configuration).getPendingMaterialsBusinessEntities(options)(axios, basePath);
    },
    /**
     *
     * @summary Performs a full text search across all materials linked to a material master
     * @param {string} [query]
     * @param {number} [numberOfRows]
     * @param {number} [page]
     * @param {string} [stage]
     * @param {string} [authorId]
     * @param {number} [businessEntityId]
     * @param {string} [plant]
     * @param {number} [bomCheckId]
     * @param {string} [status]
     * @param {number} [excludeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPendingMaterials(
      query?: string,
      numberOfRows?: number,
      page?: number,
      stage?: string,
      authorId?: string,
      businessEntityId?: number,
      plant?: string,
      bomCheckId?: number,
      status?: string,
      excludeId?: number,
      options?: any,
    ) {
      return PendingMaterialsApiFp(configuration).searchPendingMaterials(
        query,
        numberOfRows,
        page,
        stage,
        authorId,
        businessEntityId,
        plant,
        bomCheckId,
        status,
        excludeId,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Post a pending material to update
     * @param {UpdatePendingMaterial} [updatePendingMaterial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePendingMaterial(updatePendingMaterial?: UpdatePendingMaterial, options?: any) {
      return PendingMaterialsApiFp(configuration).updatePendingMaterial(updatePendingMaterial, options)(
        axios,
        basePath,
      );
    },
  };
};

/**
 * PendingMaterialsApi - object-oriented interface
 * @export
 * @class PendingMaterialsApi
 * @extends {BaseAPI}
 */
export class PendingMaterialsApi extends BaseAPI {
  /**
   *
   * @summary Post a new pending material
   * @param {NewPendingMaterial} [newPendingMaterial]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public createPendingMaterial(newPendingMaterial?: NewPendingMaterial, options?: any) {
    return PendingMaterialsApiFp(this.configuration).createPendingMaterial(newPendingMaterial, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   * Delete a pending material
   * @param {number} id material Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public deletePendingMaterial(id: number, options?: any) {
    return PendingMaterialsApiFp(this.configuration).deletePendingMaterial(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export selected pending materials
   * @param {string} [stage]
   * @param {number} [jobId]
   * @param {PendingMaterialIds} [pendingMaterialIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public exportMultiplePendingMaterials(
    stage?: string,
    jobId?: number,
    pendingMaterialIds?: PendingMaterialIds,
    options?: any,
  ) {
    return PendingMaterialsApiFp(this.configuration).exportMultiplePendingMaterials(
      stage,
      jobId,
      pendingMaterialIds,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export all pending materials
   * @param {string} stage
   * @param {number} [jobId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public exportPendingMaterials(stage: string, jobId?: number, options?: any) {
    return PendingMaterialsApiFp(this.configuration).exportPendingMaterials(
      stage,
      jobId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export a single pending materials
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public exportSinglePendingMaterial(id: number, options?: any) {
    return PendingMaterialsApiFp(this.configuration).exportSinglePendingMaterial(id, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get a pending material
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public getPendingMaterial(id: number, options?: any) {
    return PendingMaterialsApiFp(this.configuration).getPendingMaterial(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available authors of pendingMaterials
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public getPendingMaterialsAuthors(options?: any) {
    return PendingMaterialsApiFp(this.configuration).getPendingMaterialsAuthors(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all available Business Entities of pendingMaterials
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public getPendingMaterialsBusinessEntities(options?: any) {
    return PendingMaterialsApiFp(this.configuration).getPendingMaterialsBusinessEntities(options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Performs a full text search across all materials linked to a material master
   * @param {string} [query]
   * @param {number} [numberOfRows]
   * @param {number} [page]
   * @param {string} [stage]
   * @param {string} [authorId]
   * @param {number} [businessEntityId]
   * @param {string} [plant]
   * @param {number} [bomCheckId]
   * @param {string} [status]
   * @param {number} [excludeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public searchPendingMaterials(
    query?: string,
    numberOfRows?: number,
    page?: number,
    stage?: string,
    authorId?: string,
    businessEntityId?: number,
    plant?: string,
    bomCheckId?: number,
    status?: string,
    excludeId?: number,
    options?: any,
  ) {
    return PendingMaterialsApiFp(this.configuration).searchPendingMaterials(
      query,
      numberOfRows,
      page,
      stage,
      authorId,
      businessEntityId,
      plant,
      bomCheckId,
      status,
      excludeId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Post a pending material to update
   * @param {UpdatePendingMaterial} [updatePendingMaterial]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PendingMaterialsApi
   */
  public updatePendingMaterial(updatePendingMaterial?: UpdatePendingMaterial, options?: any) {
    return PendingMaterialsApiFp(this.configuration).updatePendingMaterial(updatePendingMaterial, options)(
      this.axios,
      this.basePath,
    );
  }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the mapped materials and products
     * @param {string} reference Material Reference
     * @param {string} sptId Sparetech ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterialsAndProducts(reference: string, sptId: string, options: any = {}): RequestArgs {
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getMappedMaterialsAndProducts.",
        );
      }
      // verify required parameter 'sptId' is not null or undefined
      if (sptId === null || sptId === undefined) {
        throw new RequiredError(
          "sptId",
          "Required parameter sptId was null or undefined when calling getMappedMaterialsAndProducts.",
        );
      }
      const localVarPath = `/mapped/materials/{reference}/products/{sptId}`
        .replace(`{${"reference"}}`, encodeURIComponent(String(reference)))
        .replace(`{${"sptId"}}`, encodeURIComponent(String(sptId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a full product description by a SPARETECH-ID
     * @param {string} sptId SPARETECH-ID
     * @param {boolean} [withResolvedManufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(sptId: string, withResolvedManufacturer?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'sptId' is not null or undefined
      if (sptId === null || sptId === undefined) {
        throw new RequiredError("sptId", "Required parameter sptId was null or undefined when calling getProduct.");
      }
      const localVarPath = `/products/{sptId}/main`.replace(`{${"sptId"}}`, encodeURIComponent(String(sptId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withResolvedManufacturer !== undefined) {
        localVarQueryParameter["withResolvedManufacturer"] = withResolvedManufacturer;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the product-details by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProductDetails.");
      }
      const localVarPath = `/products/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the materials by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductMaterialSuggestions(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getProductMaterialSuggestions.",
        );
      }
      const localVarPath = `/products/{id}/materials`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Free text product search
     * @param {string} term
     * @param {number} [maxResults]
     * @param {number} [page]
     * @param {string} [manufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearch(
      term: string,
      maxResults?: number,
      page?: number,
      manufacturer?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'term' is not null or undefined
      if (term === null || term === undefined) {
        throw new RequiredError("term", "Required parameter term was null or undefined when calling productSearch.");
      }
      const localVarPath = `/products/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (term !== undefined) {
        localVarQueryParameter["term"] = term;
      }

      if (maxResults !== undefined) {
        localVarQueryParameter["maxResults"] = maxResults;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search for identifying fields in the products
     * @param {string} [manufacturer]
     * @param {string} [articleNumber]
     * @param {string} [typeCode]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSemanticSearch(
      manufacturer?: string,
      articleNumber?: string,
      typeCode?: string,
      limit?: number,
      page?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/products/semantic-search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (articleNumber !== undefined) {
        localVarQueryParameter["articleNumber"] = articleNumber;
      }

      if (typeCode !== undefined) {
        localVarQueryParameter["typeCode"] = typeCode;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the mapped materials and products
     * @param {string} reference Material Reference
     * @param {string} sptId Sparetech ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterialsAndProducts(
      reference: string,
      sptId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappedMaterialsAndProducts>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getMappedMaterialsAndProducts(
        reference,
        sptId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a full product description by a SPARETECH-ID
     * @param {string} sptId SPARETECH-ID
     * @param {boolean} [withResolvedManufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(
      sptId: string,
      withResolvedManufacturer?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProduct(
        sptId,
        withResolvedManufacturer,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the product-details by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDetails>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProductDetails(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the materials by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductMaterialSuggestions(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProductMaterialSuggestions(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Free text product search
     * @param {string} term
     * @param {number} [maxResults]
     * @param {number} [page]
     * @param {string} [manufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearch(
      term: string,
      maxResults?: number,
      page?: number,
      manufacturer?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchResults> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).productSearch(
        term,
        maxResults,
        page,
        manufacturer,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Search for identifying fields in the products
     * @param {string} [manufacturer]
     * @param {string} [articleNumber]
     * @param {string} [typeCode]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSemanticSearch(
      manufacturer?: string,
      articleNumber?: string,
      typeCode?: string,
      limit?: number,
      page?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchResults> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).productSemanticSearch(
        manufacturer,
        articleNumber,
        typeCode,
        limit,
        page,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get the mapped materials and products
     * @param {string} reference Material Reference
     * @param {string} sptId Sparetech ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMappedMaterialsAndProducts(reference: string, sptId: string, options?: any) {
      return ProductsApiFp(configuration).getMappedMaterialsAndProducts(reference, sptId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a full product description by a SPARETECH-ID
     * @param {string} sptId SPARETECH-ID
     * @param {boolean} [withResolvedManufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(sptId: string, withResolvedManufacturer?: boolean, options?: any) {
      return ProductsApiFp(configuration).getProduct(sptId, withResolvedManufacturer, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the product-details by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(id: string, options?: any) {
      return ProductsApiFp(configuration).getProductDetails(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the materials by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductMaterialSuggestions(id: string, options?: any) {
      return ProductsApiFp(configuration).getProductMaterialSuggestions(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Free text product search
     * @param {string} term
     * @param {number} [maxResults]
     * @param {number} [page]
     * @param {string} [manufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearch(term: string, maxResults?: number, page?: number, manufacturer?: string, options?: any) {
      return ProductsApiFp(configuration).productSearch(term, maxResults, page, manufacturer, options)(axios, basePath);
    },
    /**
     *
     * @summary Search for identifying fields in the products
     * @param {string} [manufacturer]
     * @param {string} [articleNumber]
     * @param {string} [typeCode]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSemanticSearch(
      manufacturer?: string,
      articleNumber?: string,
      typeCode?: string,
      limit?: number,
      page?: number,
      options?: any,
    ) {
      return ProductsApiFp(configuration).productSemanticSearch(
        manufacturer,
        articleNumber,
        typeCode,
        limit,
        page,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
  /**
   *
   * @summary Get the mapped materials and products
   * @param {string} reference Material Reference
   * @param {string} sptId Sparetech ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getMappedMaterialsAndProducts(reference: string, sptId: string, options?: any) {
    return ProductsApiFp(this.configuration).getMappedMaterialsAndProducts(
      reference,
      sptId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a full product description by a SPARETECH-ID
   * @param {string} sptId SPARETECH-ID
   * @param {boolean} [withResolvedManufacturer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProduct(sptId: string, withResolvedManufacturer?: boolean, options?: any) {
    return ProductsApiFp(this.configuration).getProduct(
      sptId,
      withResolvedManufacturer,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the product-details by the Sparetech ID
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProductDetails(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getProductDetails(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the materials by the Sparetech ID
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProductMaterialSuggestions(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getProductMaterialSuggestions(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Free text product search
   * @param {string} term
   * @param {number} [maxResults]
   * @param {number} [page]
   * @param {string} [manufacturer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productSearch(term: string, maxResults?: number, page?: number, manufacturer?: string, options?: any) {
    return ProductsApiFp(this.configuration).productSearch(
      term,
      maxResults,
      page,
      manufacturer,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Search for identifying fields in the products
   * @param {string} [manufacturer]
   * @param {string} [articleNumber]
   * @param {string} [typeCode]
   * @param {number} [limit]
   * @param {number} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productSemanticSearch(
    manufacturer?: string,
    articleNumber?: string,
    typeCode?: string,
    limit?: number,
    page?: number,
    options?: any,
  ) {
    return ProductsApiFp(this.configuration).productSemanticSearch(
      manufacturer,
      articleNumber,
      typeCode,
      limit,
      page,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options: any = {}): RequestArgs {
      const localVarPath = `/service/backend-version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Removes the processed ADFS user from the internal in-memory cache
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProcessedAdfsUser(body: string, options: any = {}): RequestArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling removeProcessedAdfsUser.",
        );
      }
      const localVarPath = `/service/remove-processed-adfs-user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "text/plain";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"string" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ServiceApiAxiosParamCreator(configuration).getBackendVersion(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Removes the processed ADFS user from the internal in-memory cache
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProcessedAdfsUser(
      body: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ServiceApiAxiosParamCreator(configuration).removeProcessedAdfsUser(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options?: any) {
      return ServiceApiFp(configuration).getBackendVersion(options)(axios, basePath);
    },
    /**
     *
     * @summary Removes the processed ADFS user from the internal in-memory cache
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProcessedAdfsUser(body: string, options?: any) {
      return ServiceApiFp(configuration).removeProcessedAdfsUser(body, options)(axios, basePath);
    },
  };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
  /**
   *
   * @summary Returns the backend version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getBackendVersion(options?: any) {
    return ServiceApiFp(this.configuration).getBackendVersion(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Removes the processed ADFS user from the internal in-memory cache
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public removeProcessedAdfsUser(body: string, options?: any) {
    return ServiceApiFp(this.configuration).removeProcessedAdfsUser(body, options)(this.axios, this.basePath);
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new user
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(newUser: NewUser, options: any = {}): RequestArgs {
      // verify required parameter 'newUser' is not null or undefined
      if (newUser === null || newUser === undefined) {
        throw new RequiredError("newUser", "Required parameter newUser was null or undefined when calling createUser.");
      }
      const localVarPath = `/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewUser" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newUser !== undefined ? newUser : {})
        : newUser || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a user
     * @param {string} id Users\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteUser.");
      }
      const localVarPath = `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user detail
     * @param {string} id user\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getUser.");
      }
      const localVarPath = `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options: any = {}): RequestArgs {
      const localVarPath = `/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get plant value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersPlantValue(options: any = {}): RequestArgs {
      const localVarPath = `/users/plant-value`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Trigger Email Password reset flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordReset(options: any = {}): RequestArgs {
      const localVarPath = `/userinfo/password-reset`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a users own profile
     * @param {UserinfoUpdate} userinfoUpdate User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(userinfoUpdate: UserinfoUpdate, options: any = {}): RequestArgs {
      // verify required parameter 'userinfoUpdate' is not null or undefined
      if (userinfoUpdate === null || userinfoUpdate === undefined) {
        throw new RequiredError(
          "userinfoUpdate",
          "Required parameter userinfoUpdate was null or undefined when calling updateProfile.",
        );
      }
      const localVarPath = `/userinfo`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UserinfoUpdate" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userinfoUpdate !== undefined ? userinfoUpdate : {})
        : userinfoUpdate || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a user
     * @param {string} id Users\&#39;s id
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: string, newUser: NewUser, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateUser.");
      }
      // verify required parameter 'newUser' is not null or undefined
      if (newUser === null || newUser === undefined) {
        throw new RequiredError("newUser", "Required parameter newUser was null or undefined when calling updateUser.");
      }
      const localVarPath = `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewUser" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newUser !== undefined ? newUser : {})
        : newUser || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary User Information of the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userinfo(options: any = {}): RequestArgs {
      const localVarPath = `/userinfo`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new user
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(newUser: NewUser, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).createUser(newUser, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a user
     * @param {string} id Users\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).deleteUser(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user detail
     * @param {string} id user\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUser(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUsers(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get plant value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersPlantValue(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUsersPlantValue(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Trigger Email Password reset flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordReset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).passwordReset(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a users own profile
     * @param {UserinfoUpdate} userinfoUpdate User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(
      userinfoUpdate: UserinfoUpdate,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Userinfo> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).updateProfile(userinfoUpdate, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a user
     * @param {string} id Users\&#39;s id
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      id: string,
      newUser: NewUser,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).updateUser(id, newUser, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary User Information of the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userinfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Userinfo> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).userinfo(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a new user
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(newUser: NewUser, options?: any) {
      return UsersApiFp(configuration).createUser(newUser, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a user
     * @param {string} id Users\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: string, options?: any) {
      return UsersApiFp(configuration).deleteUser(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get user detail
     * @param {string} id user\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: any) {
      return UsersApiFp(configuration).getUser(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options?: any) {
      return UsersApiFp(configuration).getUsers(options)(axios, basePath);
    },
    /**
     *
     * @summary Get plant value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersPlantValue(options?: any) {
      return UsersApiFp(configuration).getUsersPlantValue(options)(axios, basePath);
    },
    /**
     *
     * @summary Trigger Email Password reset flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    passwordReset(options?: any) {
      return UsersApiFp(configuration).passwordReset(options)(axios, basePath);
    },
    /**
     *
     * @summary Update a users own profile
     * @param {UserinfoUpdate} userinfoUpdate User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(userinfoUpdate: UserinfoUpdate, options?: any) {
      return UsersApiFp(configuration).updateProfile(userinfoUpdate, options)(axios, basePath);
    },
    /**
     *
     * @summary Update a user
     * @param {string} id Users\&#39;s id
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: string, newUser: NewUser, options?: any) {
      return UsersApiFp(configuration).updateUser(id, newUser, options)(axios, basePath);
    },
    /**
     *
     * @summary User Information of the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userinfo(options?: any) {
      return UsersApiFp(configuration).userinfo(options)(axios, basePath);
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Create a new user
   * @param {NewUser} newUser User information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(newUser: NewUser, options?: any) {
    return UsersApiFp(this.configuration).createUser(newUser, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a user
   * @param {string} id Users\&#39;s id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteUser(id: string, options?: any) {
    return UsersApiFp(this.configuration).deleteUser(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get user detail
   * @param {string} id user\&#39;s id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUser(id: string, options?: any) {
    return UsersApiFp(this.configuration).getUser(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsers(options?: any) {
    return UsersApiFp(this.configuration).getUsers(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get plant value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsersPlantValue(options?: any) {
    return UsersApiFp(this.configuration).getUsersPlantValue(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Trigger Email Password reset flow
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public passwordReset(options?: any) {
    return UsersApiFp(this.configuration).passwordReset(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a users own profile
   * @param {UserinfoUpdate} userinfoUpdate User information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateProfile(userinfoUpdate: UserinfoUpdate, options?: any) {
    return UsersApiFp(this.configuration).updateProfile(userinfoUpdate, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a user
   * @param {string} id Users\&#39;s id
   * @param {NewUser} newUser User information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUser(id: string, newUser: NewUser, options?: any) {
    return UsersApiFp(this.configuration).updateUser(id, newUser, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary User Information of the logged in user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userinfo(options?: any) {
    return UsersApiFp(this.configuration).userinfo(options)(this.axios, this.basePath);
  }
}
