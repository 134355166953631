import * as React from "react";
import { useState } from "react";
import cx from "classnames";
import { Link, useRouteMatch } from "react-router-dom";

export const Section: React.FC = ({ children }) => {
  return <section className="section">{children}</section>;
};

export const Container: React.FC<
  {
    fluid?: boolean;
    content?: boolean;
  } & React.HTMLProps<HTMLDivElement>
> = ({ fluid, children, content, style }) => {
  return (
    <div className={cx("container", { "is-fluid": fluid, content: content })} style={style}>
      {children}
    </div>
  );
};

export const Hero: React.FC<{
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  supertitle?: string | React.ReactNode;
  isMedium?: boolean;
  centeredText?: boolean;
}> = ({ title, subtitle, supertitle, isMedium = false, centeredText = false }) => (
  <section className={cx("hero is-primary sparetech-bg", { "is-medium": isMedium })}>
    <div className={cx("hero-body", { "has-text-centered": centeredText })}>
      <Container>
        {supertitle && <h2 className="subtitle">{supertitle}</h2>}
        <h1 className="title">{title}</h1>
        {subtitle && <h2 className="subtitle">{subtitle}</h2>}
      </Container>
    </div>
  </section>
);

export const Box: React.FC<{
  title?: React.ReactNode;
  right?: React.ReactNode;
  maxWidth?: string | number;
}> = ({ title, children, right, maxWidth = "auto" }) => {
  return (
    <div className="card" style={{ marginBottom: 30, maxWidth }}>
      {title && (
        <header className="card-header">
          <p className="card-header-title">{title}</p>
          {right}
        </header>
      )}
      {children && <div className="card-content">{children}</div>}
    </div>
  );
};

export const ToggleBox: React.FC<{
  title: string;
  initial?: boolean;
}> = ({ children, initial = false, ...rest }) => {
  const [show, setShow] = useState(initial);
  const toggle = (e: any) => {
    e.preventDefault();
    setShow(value => !value);
  };
  return (
    <Box
      {...rest}
      right={
        <a className="card-header-icon" href={"/"} onClick={toggle}>
          <span className="icon">
            {show ? (
              <i className="fas fa-angle-up" aria-hidden="true" />
            ) : (
              <i className="fas fa-angle-down" aria-hidden="true" />
            )}
          </span>
        </a>
      }
    >
      {show && children}
    </Box>
  );
};

interface TabLink {
  name: string;
  to: string;
}

const TabNavLink: React.FC<TabLink> = ({ name, to }) => {
  const match = useRouteMatch({ path: to, exact: true });
  return (
    <li className={match ? "is-active" : ""}>
      <Link to={to}>{name}</Link>
    </li>
  );
};

export const TabLinks: React.FC<{
  links: TabLink[];
}> = ({ links = [] }) => {
  return (
    <div className="tabs is-boxed is-medium">
      <ul>
        {links.map(tab => (
          <TabNavLink key={tab.to} {...tab} />
        ))}
      </ul>
    </div>
  );
};
