import { useAuth } from "./Auth/useAuth";
import { TrackEventParams, TrackPageViewParams, TrackSiteSearchParams } from "@datapunt/matomo-tracker-react/lib/types";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useCallback, useMemo } from "react";
import { Modify } from "./helpers/Types";

const doNothing = () => {};

// Keep in sync with https://www.notion.so/sparetech/Events-458a64a3dfb14239a6b9b355f8c13133 !
export type OperatorAppEventCategories =
  | "ChangeRequest"
  | "CreateJob"
  | "CreatePendingMaterial"
  | "DownloadMaterials"
  | "ExtensionMaterials"
  | "Inquiry"
  | "Management"
  | "Material"
  | "MaterialExtension"
  | "NewJob"
  | "Search"
  | "UpdatePendingMaterial"
  | "UploadMaterialMaster";

export type AdminAppEventCategories =
  | "ListMatching"
  | "UploadMaterialMaster"
  | "ListVsListMatching"
  | "DuplicateMatching"
  | "DBMatching";

export type PartnersAppEventCategories = "UploadCatalog";

type SptTrackEventParams = Modify<
  TrackEventParams,
  {
    category: OperatorAppEventCategories | AdminAppEventCategories | PartnersAppEventCategories;
  }
>;

type UseTrackingType = Modify<
  typeof useMatomo,
  () => { trackEvent: (params: SptTrackEventParams) => void | undefined }
>;

const useTracking: UseTrackingType = () => {
  // TODO: Figure out why this isn't "stable"
  const matomo = useMatomo();

  const { isGhost, profile } = useAuth();

  const shouldNotTrack = useMemo(() => isGhost || profile?.email?.endsWith("@sparetech.de"), [profile, isGhost]);

  const customDimensions = useMemo(
    () => (profile?.companyName ? [{ id: 2, value: profile.companyName }] : undefined),
    [profile],
  );

  const _trackEvent = useCallback(
    (params: SptTrackEventParams) => {
      matomo.trackEvent({ ...params, customDimensions });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customDimensions],
  );

  const _trackPageView = useCallback(
    (params: TrackPageViewParams) => {
      matomo.trackPageView({ ...params, customDimensions });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customDimensions],
  );

  const _trackSiteSearch = useCallback(
    (params: TrackSiteSearchParams) => {
      matomo.trackSiteSearch({ ...params, customDimensions });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customDimensions],
  );

  const trackEvent = useMemo(() => (shouldNotTrack ? doNothing : _trackEvent), [shouldNotTrack, _trackEvent]);
  const trackPageView = useMemo(() => (shouldNotTrack ? doNothing : _trackPageView), [shouldNotTrack, _trackPageView]);
  const trackSiteSearch = useMemo(
    () => (shouldNotTrack ? doNothing : _trackSiteSearch),
    [shouldNotTrack, _trackSiteSearch],
  );

  const trackLink = useMemo(() => (shouldNotTrack ? doNothing : matomo.trackLink), [shouldNotTrack, matomo]);
  const trackEvents = useMemo(() => (shouldNotTrack ? doNothing : matomo.trackEvents), [shouldNotTrack, matomo]);

  const enableLinkTracking = matomo.enableLinkTracking;
  const pushInstruction = matomo.pushInstruction;

  return {
    trackEvent,
    trackEvents,
    trackPageView,
    trackSiteSearch,
    trackLink,
    enableLinkTracking,
    pushInstruction,
  };
};

export default useTracking;
