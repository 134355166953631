// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from "axios";
import Auth from "../Auth/Auth";
import { getTransactionId, setTransactionId } from "../sentry";
import i18n from "i18next";
import Emitter, { NOT_AUTHORISED } from "../helpers/eventemitter";
import * as Sentry from "@sentry/react";

// sentry captures an exception when you reject a promise with a non-error
// Errors only take a string argument
// this custom error extends Error and exposes `data` to prevent
// capturing type errors when an error is accessed per `error.data`
class LoginError extends Error {
  data = "Login required";
  constructor() {
    super("Login required");
  }
}

// global axios request interceptor to add access_token(JWT)
axios.interceptors.request.use(
  function (config) {
    const { accessToken } = Auth;

    // besides the transaction id sentry AUTOMATICALLY adds a header called 'sentry-trace'
    config.headers["X-Transaction-ID"] = getTransactionId();

    if (accessToken != null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.headers["Accept-Language"] = i18n.language;

    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    // we only set the transactionID on sentry when the request fails, otherwise
    // due to concurrent requests a error might be thrown for a request with another transaction ID!
    if (error?.response?.config?.headers) {
      const transactionId = error.response.config.headers["X-Transaction-ID"];
      setTransactionId(transactionId);
    }

    // Handle token refresh
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      return new Promise<Promise<never>>((resolve, reject) =>
        Auth.silentAuth()
          .then(_ => {
            const { accessToken } = Auth;
            // besides the transaction id sentry AUTOMATICALLY adds a header called 'sentry-trace'
            error.config.headers["X-Transaction-ID"] = getTransactionId();
            if (accessToken != null) {
              error.config.headers.Authorization = `Bearer ${accessToken}`;
            }
            // Redo failed request with new accessToken
            resolve(axios.request(error.config));
          })
          .catch(_ => {
            // Could not refresh token => Force new sign in
            Auth.signIn();
            reject(new LoginError());
          }),
      );
    } else if (status === 405) {
      const errorResponse = error.response.data;
      const errorMessage =
        typeof errorResponse === "object" ? new TextDecoder("utf-8").decode(errorResponse) : errorResponse;

      Emitter.emit(NOT_AUTHORISED, errorMessage);
    } else if (status >= 500) {
      Sentry.captureException(error);
    } else if (!!status) {
      Sentry.captureException(error, { level: "info" });
    }

    return Promise.reject(error.response);
  },
);
