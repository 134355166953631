import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./useAuth";
import auth from "./Auth";
import StatusPage from "../components/StatusPage";
import { useTranslation } from "react-i18next";
import { useMatomo } from "../../index";
import { useHistory } from "react-router";

export const ProtectedRoute: React.FC<{ component: any; path: string; exact?: boolean }> = props => {
  const { component: Component, path } = props;
  const { isProcessing, isAuthenticated }: any = useAuth();
  const { trackPageView } = useMatomo();
  const {
    location: { pathname },
  } = useHistory();

  const { t } = useTranslation();
  const sessionValidationLabel = t("common:validating session");
  const forwardingLabel = t("common:forwarding");
  const redirectionMessage = t("common:you will be redirected to the desired page");

  if (!isProcessing && isAuthenticated === false) {
    auth.signIn(pathname);
  }

  return (
    <Route
      exact={props.exact}
      path={path}
      render={props => {
        if (isProcessing) return <h3 className="text-center">{sessionValidationLabel}...</h3>;
        if (!isProcessing && !isAuthenticated) {
          return <StatusPage type={`${forwardingLabel}...`}>{redirectionMessage}</StatusPage>;
        }

        // @ts-ignore
        trackPageView();

        return <Component {...props} />;
      }}
    />
  );
};
