import React, { lazy, Suspense } from "react";
import { useAuth, UserProfileErrors } from "@sparetech/common/src/Auth/useAuth";
import Loading from "./Loading";

const NoProfile = lazy(() => import("./NoProfile"));
const NoBusinessEntity = lazy(() => import("./NoBusinessEntity"));
const RefreshRequired = lazy(() => import("./RefreshRequired"));

const BlockApp: React.FC = ({ children }) => {
  return <div id={"block-app-usage"}>{children}</div>;
};

const fallback = (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
    <Loading loading={true} />
  </div>
);

const MissingInfoObserver: React.FC = () => {
  const { isAuthenticated, isChecking, errors } = useAuth();

  if (isChecking || !isAuthenticated) {
    return null;
  }

  if (errors === UserProfileErrors.NO_PROFILE) {
    return (
      <BlockApp>
        <Suspense fallback={fallback}>
          <NoProfile />
        </Suspense>
      </BlockApp>
    );
  } else if (errors === UserProfileErrors.REFRESH_REQUIRED) {
    return (
      <BlockApp>
        <Suspense fallback={fallback}>
          <RefreshRequired />
        </Suspense>
      </BlockApp>
    );
  } else if (errors === UserProfileErrors.NO_BUSINESS_ENTITY) {
    return (
      <BlockApp>
        <Suspense fallback={fallback}>
          <NoBusinessEntity />
        </Suspense>
      </BlockApp>
    );
  }

  return null;
};

export default MissingInfoObserver;
