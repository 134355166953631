import { createCanBoundTo } from "@casl/react";
import ability from "./ability";

export enum actions {
  read = "read",
  create = "create",
  edit = "edit",
  review = "review",
  export = "export",
  apply = "apply",
  deleteCreated = "delete-created",
  deleteInCreation = "delete-in-creation",
  manage = "manage",
  upload = "upload",
  view = "view",
  takeOver = "take-over",
  preview = "preview",
  use = "use",
}

export enum resources {
  material = "material",
  change = "change",
  user = "user",
  job = "job",
  entities = "entities",
  match = "match",
  betaFeatures = "beta-features",
  mmFilters = "mm-filters",
  statistics = "statistics",
}

export default createCanBoundTo(ability);
