import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initSentry } from "@sparetech/common";
import frontendVersion from "@sparetech/common/src/helpers/version";

initSentry(frontendVersion);

ReactDOM.render(<App frontendVersion={frontendVersion} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
