import EventEmitter from "eventemitter3";
const eventEmitter = new EventEmitter();

export const NOT_AUTHORISED = "NOT_AUTHORISED";
export const FOCUS_TEXTAREA = "FOCUS_TEXTAREA";
export const TEXTAREA_FEEDBACK = "TEXTAREA_FEEDBACK";

const Emitter = {
  on: (event: string, fn: (value: any) => void) => eventEmitter.on(event, fn),
  once: (event: string, fn: (value: any) => void) => eventEmitter.once(event, fn),
  off: (event: string, fn: (value: any) => void) => eventEmitter.off(event, fn),
  emit: (event: string, payload?: any) => eventEmitter.emit(event, payload),
};

export default Emitter;
