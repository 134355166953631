import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const getEnv = (): string | undefined => {
  const hostname = window.location.hostname.toLowerCase();

  if (hostname.includes("localhost")) {
    return undefined;
  }

  if (!hostname.includes("sparetech.io")) {
    return undefined;
  }

  const name = hostname.match(/(?:admin|app|partner)\.([a-z0-9-]*)\.?sparetech.io/)?.[1];

  return name === "" ? "production" : name;
};

export const getTransactionId = (): string => {
  const transactionId = Math.random().toString(36).substr(2, 9);

  return transactionId;
};

export const setTransactionId = (transactionId: string) => {
  Sentry.configureScope(function (scope) {
    scope.setTag("transaction_id", transactionId);
  });
};

export const initSentry = (release: string) => {
  const env = getEnv();
  if (env !== undefined) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: env,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      release: release,
      // There seems to be nothing we can do about ChunkLoadErrors resulting from
      // stale artifacts, and they have no impact on the user, so we ignore them
      ignoreErrors: ["ChunkLoadError"],
    });
  }
};
