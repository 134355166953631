import * as React from "react";
import { useState, useEffect } from "react";
import Icon from "./system/Icon";
import Stack, { Box, Space } from "./system/Layout";
import { Text } from "./system/Typography";
import { useTranslation } from "react-i18next";

const Loading: React.FC<{
  loading: boolean;
  error?: {
    message: string;
    name: string;
  };
  timeout?: number;
}> = ({ loading, timeout = 5000, error }) => {
  const DEFAULT_DELAY = 250;

  const [isDelayed, setDelayed] = useState(true);
  const [isLongRunning, setLongRunning] = useState(false);

  const { t } = useTranslation();
  const errorLabel = t("common:error");
  const loadingLabel = t("common:loading");
  const longLoadingMessage = t("common:longer than expected");

  useEffect(() => {
    const delayTO = setTimeout(() => setDelayed(false), DEFAULT_DELAY);
    const longRunningTO = setTimeout(() => setLongRunning(true), timeout);
    return () => {
      clearTimeout(delayTO);
      clearTimeout(longRunningTO);
    };
  }, [timeout]);

  if (isDelayed) {
    return null;
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <article className="message is-danger" style={{ maxWidth: 400 }}>
          <div className="message-header">
            <p>
              <span className="icon">
                <i className="fas fa-exclamation-triangle" />
              </span>
              <span> {errorLabel}</span>
            </p>
          </div>
          <div className="message-body">{error?.message}</div>
        </article>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container has-text-centered content">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div>
            <Box>
              <Space vertical={8} horizontal={16}>
                <Stack centered space={4}>
                  <Icon name={"Logo"} isSpinning />
                  <span data-tooltip={isLongRunning && longLoadingMessage}>
                    <Text emphasized>{loadingLabel}</Text>
                  </span>
                </Stack>
              </Space>
            </Box>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Loading;
