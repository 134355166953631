import * as React from "react";
import { ReactNode, RefObject, useState } from "react";
import c from "classnames";
import Icon, { IconName } from "./Icon";
import { Heading } from "./Typography";
import { cypressAttributes } from "@sparetech/common/src/helpers/cypress-constants";

type Spacing = 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64;
type subGrid = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export const Columns: React.FC<{
  classNames?: string;
  hasColumns?: subGrid;
  top?: number;
  style?: React.CSSProperties;
}> = ({ children, classNames, style, top = 0, hasColumns }) => (
  <div
    className={c("columns", classNames, { [`has-${hasColumns}-columns`]: hasColumns })}
    style={Object.assign({ paddingTop: top * 8 }, style)}
  >
    {children}
  </div>
);
export const Column: React.FC<{
  is?: number;
  offset?: number;
  classNames?: string;
  style?: React.CSSProperties;
}> = ({ children, is, offset, classNames, style }) => (
  <div
    className={c(
      "column",
      {
        [`is-${is}`]: is,
        [`is-offset-${offset}`]: offset,
      },
      classNames,
    )}
    style={style}
  >
    {children}
  </div>
);

const Stack: React.FC<{
  padding?: number;
  around?: Spacing;
  space?: number;
  centered?: boolean;
}> = ({ children, space = 1, around, centered }) => {
  return (
    <div
      className={c(`stack space-${space * 8}`, { centered: centered })}
      style={around && { padding: `${around}px 0` }}
    >
      {React.Children.map(children, (child, index) => (
        <div className={"stack-item"} key={index}>
          {child}
        </div>
      ))}
    </div>
  );
};

export const HStack: React.FC<{
  padding?: number;
  around?: Spacing;
  space?: number;
  centered?: boolean;
  fullHeight?: boolean;
  justifyContent?: string;
}> = ({ children, space = 1, around, centered, fullHeight, justifyContent = "inherit" }) => {
  return (
    <div
      className={c(`h-stack space-${space * 8}`, { centered }, { "is-fullheight": fullHeight })}
      style={{ padding: around ? `${around}px 0` : "inherit", justifyContent }}
    >
      {React.Children.map(children, (child, index) => (
        <div className={"stack-item"} key={index}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default Stack;

export const Placeholder: React.FC<{ text: string; style?: React.CSSProperties }> = ({ text, style }) => (
  <div className={"placeholder"} style={style}>
    {text}
  </div>
);

export const Space: React.FC<{ vertical?: number; horizontal?: number }> = ({
  vertical = 0,
  horizontal = 0,
  children,
}) => <div style={{ padding: `${vertical * 8}px ${horizontal * 8}px`, width: "100%" }}>{children}</div>;

interface BoxProps {
  clickable?: boolean;
  active?: boolean;
  disabled?: boolean;
  padding?: number;
  fullHeight?: boolean;
  scrollableX?: boolean;
  scrollableY?: boolean;
  onClick?: () => void;
  hidden?: boolean;
}

interface TitleBoxProps extends BoxProps {
  title: string | ReactNode;
  collapsible?: boolean;
  collapsed?: boolean;
  titleZIndex?: number;
  innerRef?: RefObject<HTMLDivElement>;
  isTitleSticky?: boolean;
}

export const TitleBox: React.FC<TitleBoxProps> = ({
  title,
  children,
  collapsible = false,
  collapsed = false,
  titleZIndex,
  innerRef,
  isTitleSticky = true,
  ...props
}) => {
  const [show, setShow] = useState(!collapsed);
  return (
    <Box hidden={!show} {...props}>
      <div
        className={c("box-title", { "box-title-relative": !isTitleSticky })}
        style={titleZIndex === undefined ? undefined : { zIndex: titleZIndex }}
        ref={innerRef}
      >
        {title}
        {collapsible && (
          <span style={{ position: "absolute", right: 16 }}>
            <Icon name={show ? "ArrowUp" : "ArrowDown"} onClick={_ => setShow(t => !t)} />
          </span>
        )}
      </div>
      {show ? children : null}
    </Box>
  );
};

export const Box: React.FC<BoxProps> = ({
  children,
  padding,
  clickable,
  active,
  disabled,
  fullHeight,
  onClick,
  scrollableX,
  scrollableY,
  hidden,
}) => {
  return (
    <div
      className={c("box", {
        "is-clickable": clickable && !disabled,
        "is-active": active,
        "is-disabled": disabled,
        "is-fullheight": fullHeight,
        "is-collapsed": hidden,
      })}
      style={{
        padding: padding ? padding * 8 : 0,
        overflowY: scrollableY ? "auto" : "inherit",
        overflowX: scrollableX ? "auto" : scrollableY ? "hidden" : "inherit",
      }}
      onClick={() => {
        if (!disabled && clickable && onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

interface NavBoxProps extends BoxProps {
  onBack: (() => void) | null;
  rightIconName?: IconName;
  onRightAction?: () => void;
  title?: string;
}
// FIXME: backLink won't work, it has to be done via a onBack function!!!
export const NavBox: React.FC<NavBoxProps> = ({
  rightIconName = null,
  onBack,
  onRightAction,
  title,
  children,
  ...props
}) => {
  return (
    <Box {...props}>
      <div className="box-icons">
        {onBack !== null && (
          <span className="left">
            <HStack space={5}>
              <Icon name={"ArrowLeft"} onClick={onBack} data-cy={cypressAttributes.goBackArrow} />
              {title && <Heading level={3}>{title}</Heading>}
            </HStack>
          </span>
        )}
        {rightIconName && onRightAction && (
          <span className="right">
            <Icon
              name={rightIconName}
              onClick={onRightAction as () => void}
              data-cy={cypressAttributes.partiallySaveMaterial}
            />
          </span>
        )}
      </div>

      {children}
    </Box>
  );
};

export const SpaceBetween: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, style, ...props }) => {
  return (
    <div style={Object.assign({}, style, { display: "flex", justifyContent: "space-between" })} {...props}>
      {children}
    </div>
  );
};
