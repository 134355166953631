// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Manufacturer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface Catalog
 */
export interface Catalog {
  /**
   *
   * @type {number}
   * @memberof Catalog
   */
  id: number;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof Catalog
   */
  files: Array<FileReference>;
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  uploadedBy?: string;
}
/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {string}
   * @memberof Client
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  logo: string;
}
/**
 *
 * @export
 * @interface EmbeddedProviderInfo
 */
export interface EmbeddedProviderInfo {
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  logo?: string;
}
/**
 *
 * @export
 * @interface FileReference
 */
export interface FileReference {
  /**
   *
   * @type {string}
   * @memberof FileReference
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FileReference
   */
  originalFileName: string;
}
/**
 *
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
  /**
   *
   * @type {number}
   * @memberof Inquiry
   */
  id: number;
  /**
   *
   * @type {Client}
   * @memberof Inquiry
   */
  client: Client;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  comment?: string;
  /**
   *
   * @type {User}
   * @memberof Inquiry
   */
  user: User;
  /**
   *
   * @type {InquiryMeta}
   * @memberof Inquiry
   */
  meta: InquiryMeta;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  dueDate: string;
}
/**
 *
 * @export
 * @interface InquiryDetails
 */
export interface InquiryDetails {
  /**
   *
   * @type {number}
   * @memberof InquiryDetails
   */
  id: number;
  /**
   *
   * @type {Client}
   * @memberof InquiryDetails
   */
  client: Client;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  createdAt: string;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof InquiryDetails
   */
  files: Array<FileReference>;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  comment?: string;
  /**
   *
   * @type {User}
   * @memberof InquiryDetails
   */
  user: User;
  /**
   *
   * @type {InquiryMeta}
   * @memberof InquiryDetails
   */
  meta: InquiryMeta;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  dueDate: string;
  /**
   *
   * @type {Array<InquiryResult>}
   * @memberof InquiryDetails
   */
  results: Array<InquiryResult>;
}
/**
 *
 * @export
 * @interface InquiryMeta
 */
export interface InquiryMeta {
  /**
   *
   * @type {number}
   * @memberof InquiryMeta
   */
  numberOfItems: number;
}
/**
 *
 * @export
 * @interface InquiryResult
 */
export interface InquiryResult {
  /**
   *
   * @type {number}
   * @memberof InquiryResult
   */
  id: number;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof InquiryResult
   */
  files: Array<FileReference>;
  /**
   *
   * @type {string}
   * @memberof InquiryResult
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof InquiryResult
   */
  date: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MachineOperatorRoles {
  ClientAdministrator = "Client Administrator",
  MaterialCreationPlanner = "Material Creation Planner",
  MaterialCreationUploader = "Material Creation Uploader",
  MaterialChangePlanner = "Material Change Planner",
  MaterialChangeUploader = "Material Change Uploader",
  DataCleansingPlanner = "Data Cleansing Planner",
  BetaUser = "Beta User",
}

/**
 *
 * @export
 * @interface ManufacturerAddress
 */
export interface ManufacturerAddress {
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  zipCode?: string;
}
/**
 *
 * @export
 * @interface ManufacturerConventions
 */
export interface ManufacturerConventions {
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  longDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  remarks?: string;
}
/**
 *
 * @export
 * @interface ManufacturerDetails
 */
export interface ManufacturerDetails {
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  legalName: string;
  /**
   *
   * @type {ManufacturerAddress}
   * @memberof ManufacturerDetails
   */
  address?: ManufacturerAddress;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  abbreviation: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  duns?: string;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  isSupplier: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  hasProducts: boolean;
  /**
   *
   * @type {ManufacturerConventions}
   * @memberof ManufacturerDetails
   */
  conventions?: ManufacturerConventions;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  parent?: string;
  /**
   *
   * @type {number}
   * @memberof ManufacturerDetails
   */
  numberOfProducts?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerDetails
   */
  synonyms: Array<string>;
  /**
   *
   * @type {ManufacturerIdentificationRules}
   * @memberof ManufacturerDetails
   */
  identification?: ManufacturerIdentificationRules;
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerDetails
   */
  relatedManufacturers?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  providesObsolescenceInfo: boolean;
}
/**
 *
 * @export
 * @interface ManufacturerIdentificationRules
 */
export interface ManufacturerIdentificationRules {
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerIdentificationRules
   */
  fields: Array<string>;
}
/**
 *
 * @export
 * @interface ManufacturerShort
 */
export interface ManufacturerShort {
  /**
   *
   * @type {string}
   * @memberof ManufacturerShort
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerShort
   */
  name: string;
}
/**
 *
 * @export
 * @interface MatchStatistic
 */
export interface MatchStatistic {
  /**
   *
   * @type {number}
   * @memberof MatchStatistic
   */
  matchedCount: number;
  /**
   *
   * @type {number}
   * @memberof MatchStatistic
   */
  notMatchedCount?: number;
  /**
   *
   * @type {string}
   * @memberof MatchStatistic
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface NewCatalog
 */
export interface NewCatalog {
  /**
   *
   * @type {Array<string>}
   * @memberof NewCatalog
   */
  files: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NewCatalog
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface NewInquiryResult
 */
export interface NewInquiryResult {
  /**
   *
   * @type {Array<string>}
   * @memberof NewInquiryResult
   */
  files: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NewInquiryResult
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sparetechId: string;
  /**
   *
   * @type {ManufacturerShort}
   * @memberof Product
   */
  manufacturer: ManufacturerShort;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  gtin?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  shortDescription: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  longDescription: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  image?: string;
  /**
   *
   * @type {ProductSource}
   * @memberof Product
   */
  source: ProductSource;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  isConfigurable: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  successorId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  predecessors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  material?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  duplicateMaterial?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ProductAttribute
 */
export interface ProductAttribute {
  /**
   *
   * @type {string}
   * @memberof ProductAttribute
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isIdAttribute: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isRelevant: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductAttribute
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  parentId?: string;
}
/**
 *
 * @export
 * @interface ProductDetails
 */
export interface ProductDetails {
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  gtin?: string;
  /**
   *
   * @type {EmbeddedProviderInfo}
   * @memberof ProductDetails
   */
  provider: EmbeddedProviderInfo;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  supplierArticleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  source: string;
  /**
   *
   * @type {ProductCategory}
   * @memberof ProductDetails
   */
  category?: ProductCategory;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  expiryDate?: string;
  /**
   *
   * @type {ProductImage}
   * @memberof ProductDetails
   */
  image?: ProductImage;
  /**
   *
   * @type {SourcingInformation}
   * @memberof ProductDetails
   */
  sourcingInfo?: SourcingInformation;
  /**
   *
   * @type {Array<ProductValue>}
   * @memberof ProductDetails
   */
  values?: Array<ProductValue>;
}
/**
 *
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  caption?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  type: string;
}
/**
 *
 * @export
 * @interface ProductMatchingStatistics
 */
export interface ProductMatchingStatistics {
  /**
   *
   * @type {number}
   * @memberof ProductMatchingStatistics
   */
  matchedCount: number;
  /**
   *
   * @type {number}
   * @memberof ProductMatchingStatistics
   */
  notMatchedCount: number;
  /**
   *
   * @type {string}
   * @memberof ProductMatchingStatistics
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface ProductPrice
 */
export interface ProductPrice {
  /**
   *
   * @type {number}
   * @memberof ProductPrice
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ProductPrice
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof ProductPrice
   */
  originalPrice?: number;
}
/**
 *
 * @export
 * @interface ProductSearch
 */
export interface ProductSearch {
  /**
   *
   * @type {string}
   * @memberof ProductSearch
   */
  term: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearch
   */
  maxResults?: number;
  /**
   *
   * @type {number}
   * @memberof ProductSearch
   */
  page?: number;
  /**
   *
   * @type {string}
   * @memberof ProductSearch
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSearch
   */
  category?: string;
}
/**
 *
 * @export
 * @interface ProductSearchAggregations
 */
export interface ProductSearchAggregations {
  /**
   *
   * @type {Array<ProductSearchFilter>}
   * @memberof ProductSearchAggregations
   */
  category?: Array<ProductSearchFilter>;
  /**
   *
   * @type {Array<ProductSearchFilter>}
   * @memberof ProductSearchAggregations
   */
  manufacturer?: Array<ProductSearchFilter>;
}
/**
 *
 * @export
 * @interface ProductSearchFilter
 */
export interface ProductSearchFilter {
  /**
   *
   * @type {string}
   * @memberof ProductSearchFilter
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearchFilter
   */
  numberOfProducts: number;
}
/**
 *
 * @export
 * @interface ProductSearchResults
 */
export interface ProductSearchResults {
  /**
   *
   * @type {ProductSearchAggregations}
   * @memberof ProductSearchResults
   */
  aggregations?: ProductSearchAggregations;
  /**
   *
   * @type {number}
   * @memberof ProductSearchResults
   */
  numberOfResults?: number;
  /**
   *
   * @type {Array<Product>}
   * @memberof ProductSearchResults
   */
  results: Array<Product>;
}
/**
 *
 * @export
 * @interface ProductSource
 */
export interface ProductSource {
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  origin: string;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  format: string;
}
/**
 *
 * @export
 * @interface ProductValue
 */
export interface ProductValue {
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  unit?: string;
  /**
   *
   * @type {ProductAttribute}
   * @memberof ProductValue
   */
  attribute: ProductAttribute;
}
/**
 *
 * @export
 * @interface SourcingInformation
 */
export interface SourcingInformation {
  /**
   *
   * @type {ProductPrice}
   * @memberof SourcingInformation
   */
  price?: ProductPrice;
  /**
   *
   * @type {string}
   * @memberof SourcingInformation
   */
  delivery?: string;
  /**
   *
   * @type {string}
   * @memberof SourcingInformation
   */
  link?: string;
}
/**
 *
 * @export
 * @interface Top10Product
 */
export interface Top10Product {
  /**
   *
   * @type {string}
   * @memberof Top10Product
   */
  sparetechId?: string;
  /**
   *
   * @type {number}
   * @memberof Top10Product
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof Top10Product
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof Top10Product
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Top10Product
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Top10Product
   */
  shortText?: string;
  /**
   *
   * @type {string}
   * @memberof Top10Product
   */
  image?: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  manufacturerId?: string;
  /**
   *
   * @type {Array<MachineOperatorRoles>}
   * @memberof User
   */
  roles?: Array<MachineOperatorRoles>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  deleted?: boolean;
}

/**
 * CatalogsApi - axios parameter creator
 * @export
 */
export const CatalogsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get catalogs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(options: any = {}): RequestArgs {
      const localVarPath = `/catalogs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload new catalog
     * @param {NewCatalog} [newCatalog]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCatalog(newCatalog?: NewCatalog, options: any = {}): RequestArgs {
      const localVarPath = `/catalogs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewCatalog" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newCatalog !== undefined ? newCatalog : {})
        : newCatalog || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CatalogsApi - functional programming interface
 * @export
 */
export const CatalogsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get catalogs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Catalog>> {
      const localVarAxiosArgs = CatalogsApiAxiosParamCreator(configuration).getCatalogs(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload new catalog
     * @param {NewCatalog} [newCatalog]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCatalog(
      newCatalog?: NewCatalog,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalog> {
      const localVarAxiosArgs = CatalogsApiAxiosParamCreator(configuration).uploadCatalog(newCatalog, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CatalogsApi - factory interface
 * @export
 */
export const CatalogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get catalogs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(options?: any) {
      return CatalogsApiFp(configuration).getCatalogs(options)(axios, basePath);
    },
    /**
     *
     * @summary Upload new catalog
     * @param {NewCatalog} [newCatalog]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCatalog(newCatalog?: NewCatalog, options?: any) {
      return CatalogsApiFp(configuration).uploadCatalog(newCatalog, options)(axios, basePath);
    },
  };
};

/**
 * CatalogsApi - object-oriented interface
 * @export
 * @class CatalogsApi
 * @extends {BaseAPI}
 */
export class CatalogsApi extends BaseAPI {
  /**
   *
   * @summary Get catalogs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogsApi
   */
  public getCatalogs(options?: any) {
    return CatalogsApiFp(this.configuration).getCatalogs(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Upload new catalog
   * @param {NewCatalog} [newCatalog]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogsApi
   */
  public uploadCatalog(newCatalog?: NewCatalog, options?: any) {
    return CatalogsApiFp(this.configuration).uploadCatalog(newCatalog, options)(this.axios, this.basePath);
  }
}

/**
 * InquiriesApi - axios parameter creator
 * @export
 */
export const InquiriesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Post an inquiry result in the scope of a manufacturer
     * @param {number} id
     * @param {NewInquiryResult} [newInquiryResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiryResult(id: number, newInquiryResult?: NewInquiryResult, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling createInquiryResult.");
      }
      const localVarPath = `/inquiries/{id}/result`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewInquiryResult" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newInquiryResult !== undefined ? newInquiryResult : {})
        : newInquiryResult || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all inquiries in the scope of a manufacturer
     * @param {number} [client]
     * @param {string} [user]
     * @param {string} [status]
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInquiries(
      client?: number,
      user?: string,
      status?: string,
      page?: number,
      limit?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/inquiries`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (client !== undefined) {
        localVarQueryParameter["client"] = client;
      }

      if (user !== undefined) {
        localVarQueryParameter["user"] = user;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an inquiry in the scope of a manufacturer
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInquiry(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getInquiry.");
      }
      const localVarPath = `/inquiries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InquiriesApi - functional programming interface
 * @export
 */
export const InquiriesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Post an inquiry result in the scope of a manufacturer
     * @param {number} id
     * @param {NewInquiryResult} [newInquiryResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiryResult(
      id: number,
      newInquiryResult?: NewInquiryResult,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryResult> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).createInquiryResult(
        id,
        newInquiryResult,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all inquiries in the scope of a manufacturer
     * @param {number} [client]
     * @param {string} [user]
     * @param {string} [status]
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInquiries(
      client?: number,
      user?: string,
      status?: string,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inquiry>> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).getAllInquiries(
        client,
        user,
        status,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get an inquiry in the scope of a manufacturer
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInquiry(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryDetails> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).getInquiry(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InquiriesApi - factory interface
 * @export
 */
export const InquiriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Post an inquiry result in the scope of a manufacturer
     * @param {number} id
     * @param {NewInquiryResult} [newInquiryResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiryResult(id: number, newInquiryResult?: NewInquiryResult, options?: any) {
      return InquiriesApiFp(configuration).createInquiryResult(id, newInquiryResult, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all inquiries in the scope of a manufacturer
     * @param {number} [client]
     * @param {string} [user]
     * @param {string} [status]
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInquiries(client?: number, user?: string, status?: string, page?: number, limit?: number, options?: any) {
      return InquiriesApiFp(configuration).getAllInquiries(client, user, status, page, limit, options)(axios, basePath);
    },
    /**
     *
     * @summary Get an inquiry in the scope of a manufacturer
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInquiry(id: number, options?: any) {
      return InquiriesApiFp(configuration).getInquiry(id, options)(axios, basePath);
    },
  };
};

/**
 * InquiriesApi - object-oriented interface
 * @export
 * @class InquiriesApi
 * @extends {BaseAPI}
 */
export class InquiriesApi extends BaseAPI {
  /**
   *
   * @summary Post an inquiry result in the scope of a manufacturer
   * @param {number} id
   * @param {NewInquiryResult} [newInquiryResult]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public createInquiryResult(id: number, newInquiryResult?: NewInquiryResult, options?: any) {
    return InquiriesApiFp(this.configuration).createInquiryResult(
      id,
      newInquiryResult,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all inquiries in the scope of a manufacturer
   * @param {number} [client]
   * @param {string} [user]
   * @param {string} [status]
   * @param {number} [page]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public getAllInquiries(
    client?: number,
    user?: string,
    status?: string,
    page?: number,
    limit?: number,
    options?: any,
  ) {
    return InquiriesApiFp(this.configuration).getAllInquiries(
      client,
      user,
      status,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get an inquiry in the scope of a manufacturer
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public getInquiry(id: number, options?: any) {
    return InquiriesApiFp(this.configuration).getInquiry(id, options)(this.axios, this.basePath);
  }
}

/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a manufacturer by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(options: any = {}): RequestArgs {
      const localVarPath = `/manufacturer`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a manufacturer by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDetails> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getManufacturer(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Get a manufacturer by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(options?: any) {
      return ManufacturersApiFp(configuration).getManufacturer(options)(axios, basePath);
    },
  };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
  /**
   *
   * @summary Get a manufacturer by id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getManufacturer(options?: any) {
    return ManufacturersApiFp(this.configuration).getManufacturer(options)(this.axios, this.basePath);
  }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all products in the scope of a manufacturer
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllProducts(limit?: number, page?: number, options: any = {}): RequestArgs {
      const localVarPath = `/products`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a product in the scope of a manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProduct.");
      }
      const localVarPath = `/products/{id}/main`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the product-details in the scope of a manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProductDetails.");
      }
      const localVarPath = `/products/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Free text product search in the scope of a manufacturer
     * @param {ProductSearch} productSearch Term and query filters/parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearch(productSearch: ProductSearch, options: any = {}): RequestArgs {
      // verify required parameter 'productSearch' is not null or undefined
      if (productSearch === null || productSearch === undefined) {
        throw new RequiredError(
          "productSearch",
          "Required parameter productSearch was null or undefined when calling productSearch.",
        );
      }
      const localVarPath = `/products/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ProductSearch" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(productSearch !== undefined ? productSearch : {})
        : productSearch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all products in the scope of a manufacturer
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllProducts(
      limit?: number,
      page?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchResults> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getAllProducts(limit, page, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a product in the scope of a manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProduct(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the product-details in the scope of a manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDetails>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProductDetails(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Free text product search in the scope of a manufacturer
     * @param {ProductSearch} productSearch Term and query filters/parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearch(
      productSearch: ProductSearch,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchResults> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).productSearch(productSearch, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get all products in the scope of a manufacturer
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllProducts(limit?: number, page?: number, options?: any) {
      return ProductsApiFp(configuration).getAllProducts(limit, page, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a product in the scope of a manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(id: string, options?: any) {
      return ProductsApiFp(configuration).getProduct(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the product-details in the scope of a manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(id: string, options?: any) {
      return ProductsApiFp(configuration).getProductDetails(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Free text product search in the scope of a manufacturer
     * @param {ProductSearch} productSearch Term and query filters/parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productSearch(productSearch: ProductSearch, options?: any) {
      return ProductsApiFp(configuration).productSearch(productSearch, options)(axios, basePath);
    },
  };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
  /**
   *
   * @summary Get all products in the scope of a manufacturer
   * @param {number} [limit]
   * @param {number} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getAllProducts(limit?: number, page?: number, options?: any) {
    return ProductsApiFp(this.configuration).getAllProducts(limit, page, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a product in the scope of a manufacturer
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProduct(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getProduct(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the product-details in the scope of a manufacturer
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProductDetails(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getProductDetails(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Free text product search in the scope of a manufacturer
   * @param {ProductSearch} productSearch Term and query filters/parameters
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productSearch(productSearch: ProductSearch, options?: any) {
    return ProductsApiFp(this.configuration).productSearch(productSearch, options)(this.axios, this.basePath);
  }
}

/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options: any = {}): RequestArgs {
      const localVarPath = `/service/backend-version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ServiceApiAxiosParamCreator(configuration).getBackendVersion(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options?: any) {
      return ServiceApiFp(configuration).getBackendVersion(options)(axios, basePath);
    },
  };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
  /**
   *
   * @summary Returns the backend version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getBackendVersion(options?: any) {
    return ServiceApiFp(this.configuration).getBackendVersion(options)(this.axios, this.basePath);
  }
}

/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get matching statistics of the last 6 months
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchStatistics(options: any = {}): RequestArgs {
      const localVarPath = `/statistics/match-statistics`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Top10 matched products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTop10PublicProducts(options: any = {}): RequestArgs {
      const localVarPath = `/statistics/top10`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get matching statistics of the last 6 months
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchStatistics(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductMatchingStatistics>> {
      const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getMatchStatistics(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Top10 matched products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTop10PublicProducts(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Top10Product>> {
      const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getTop10PublicProducts(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get matching statistics of the last 6 months
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchStatistics(options?: any) {
      return StatisticsApiFp(configuration).getMatchStatistics(options)(axios, basePath);
    },
    /**
     *
     * @summary Get Top10 matched products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTop10PublicProducts(options?: any) {
      return StatisticsApiFp(configuration).getTop10PublicProducts(options)(axios, basePath);
    },
  };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
  /**
   *
   * @summary Get matching statistics of the last 6 months
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getMatchStatistics(options?: any) {
    return StatisticsApiFp(this.configuration).getMatchStatistics(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get Top10 matched products
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTop10PublicProducts(options?: any) {
    return StatisticsApiFp(this.configuration).getTop10PublicProducts(options)(this.axios, this.basePath);
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(options: any = {}): RequestArgs {
      const localVarPath = `/userinfo`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUser(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(options?: any) {
      return UsersApiFp(configuration).getUser(options)(axios, basePath);
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Get current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUser(options?: any) {
    return UsersApiFp(this.configuration).getUser(options)(this.axios, this.basePath);
  }
}
