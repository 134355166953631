import React from "react";
import c from "classnames";

import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-1.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up-1.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-1.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowLeft1 } from "../../assets/icons/arrow-left-1.svg";
import { ReactComponent as Abteilung } from "../../assets/icons/multiple-users-2.svg";
import { ReactComponent as Materialstamm } from "../../assets/icons/database-refresh.svg";
import { ReactComponent as User } from "../../assets/icons/single-neutral-circle.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout-1.svg";
import { ReactComponent as CheckCircle } from "../../assets/icons/check-circle-1.svg";
import { ReactComponent as AlertCircle } from "../../assets/icons/alert-circle.svg";
import { ReactComponent as ErrorCircle } from "../../assets/icons/error-circle.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as CloseBig } from "../../assets/icons/close-big.svg";
import { ReactComponent as CloudSync } from "../../assets/icons/cloud-sync.svg";
import { ReactComponent as Close32 } from "../../assets/icons/close-32.svg";
import { ReactComponent as Edit } from "../../assets/icons/pencil-1.svg";
import { ReactComponent as Delete } from "../../assets/icons/bin-1.svg";
import { ReactComponent as Attachment } from "../../assets/icons/attachment.svg";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as PhoneRetro } from "../../assets/icons/phone-retro-1.svg";
import { ReactComponent as SendMail } from "../../assets/icons/send-email-envelope.svg";
import { ReactComponent as SearchAlt } from "../../assets/icons/search-alt.svg";
import { ReactComponent as Stack } from "../../assets/icons/layout-headline.svg";
import { ReactComponent as Grid } from "../../assets/icons/layout-module.svg";
import { ReactComponent as Download } from "../../assets/icons/download-bottom.svg";
import { ReactComponent as Link } from "../../assets/icons/navigation-next.svg";
import { ReactComponent as BrowserEdge } from "../../assets/icons/browser/edge.svg";
import { ReactComponent as BrowserFirefox } from "../../assets/icons/browser/firefox.svg";
import { ReactComponent as BrowserChrome } from "../../assets/icons/browser/chrome.svg";
import { ReactComponent as Disk } from "../../assets/icons/floppy-disk.svg";
import { ReactComponent as Help } from "../../assets/icons/question-help-square.svg";
import { ReactComponent as InfoCircle } from "../../assets/icons/information-circle.svg";
import { ReactComponent as MaterialInfoCircle } from "../../assets/icons/material-design/information-outline.svg";
import { ReactComponent as View } from "../../assets/icons/view-1.svg";
import { ReactComponent as AddCircle } from "../../assets/icons/add-circle.svg";
import { ReactComponent as WaterDam } from "../../assets/icons/water-dam.svg";
import { ReactComponent as Building } from "../../assets/icons/building-1.svg";
import { ReactComponent as MultipleUsers } from "../../assets/icons/multiple-users-1.svg";
import { ReactComponent as House } from "../../assets/icons/house-chimney-1.svg";
import { ReactComponent as ArrowCircleLeft } from "../../assets/icons/arrow-circle-left.svg";
import { ReactComponent as ArrowCircleRight } from "../../assets/icons/arrow-circle-right.svg";
import { ReactComponent as Vacuum } from "../../assets/icons/cleaning-vacuum.svg";
import { ReactComponent as Broom } from "../../assets/icons/cleaning-broom.svg";
import { ReactComponent as Marker } from "../../assets/icons/content-pen-6.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Support } from "../../assets/icons/support.svg";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as CaretDown } from "../../assets/icons/caret-down.svg";
import { ReactComponent as CaretRight } from "../../assets/icons/caret-right.svg";
import { ReactComponent as CaretUp } from "../../assets/icons/caret-up.svg";
import { ReactComponent as New } from "../../assets/icons/tag-new.svg";
import { ReactComponent as Connect } from "../../assets/icons/hyperlink-3.svg";
import { ReactComponent as Duplicat } from "../../assets/icons/duplicat.svg";
import { ReactComponent as Redeemed } from "../../assets/icons/redeemed.svg";
import { ReactComponent as Obsolete } from "../../assets/icons/obsolete.svg";
import { ReactComponent as LabTube } from "../../assets/icons/lab-tube-experiment.svg";
import { ReactComponent as Beta } from "../../assets/icons/beta-svgrepo-com.svg";
import { ReactComponent as Cleansing } from "../../assets/icons/cleansing.svg";
import { ReactComponent as Match } from "../../assets/icons/match.svg";
import { ReactComponent as Gear } from "../../assets/icons/gear.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as File } from "../../assets/icons/file.svg";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { ReactComponent as Undo } from "../../assets/icons/undo.svg";
import { ReactComponent as CollapseVertical } from "../../assets/icons/move-shrink-vertical.svg";
import { ReactComponent as ExpandVertical } from "../../assets/icons/move-expand-vertical.svg";

export const icons = {
  Search,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  ArrowLeft,
  ArrowLeft1,
  Abteilung,
  Materialstamm,
  User,
  Logout,
  AlertCircle,
  CheckCircle,
  ErrorCircle,
  Check,
  CloseBig,
  Close32,
  CloudSync,
  Edit,
  Delete,
  Attachment,
  Spinner,
  Phone,
  PhoneRetro,
  SendMail,
  SearchAlt,
  Stack,
  Grid,
  Download,
  Link,
  BrowserEdge,
  BrowserFirefox,
  BrowserChrome,
  Disk,
  Help,
  InfoCircle,
  MaterialInfoCircle,
  House,
  View,
  AddCircle,
  WaterDam,
  Building,
  MultipleUsers,
  Vacuum,
  Broom,
  Marker,
  ArrowCircleLeft,
  ArrowCircleRight,
  Logo,
  Support,
  Filter,
  CaretDown,
  CaretRight,
  CaretUp,
  New,
  Connect,
  Duplicat,
  Redeemed,
  Obsolete,
  LabTube,
  Beta,
  Cleansing,
  Match,
  Gear,
  Plus,
  File,
  Upload,
  Undo,
  CollapseVertical,
  ExpandVertical,
};

export type IconName = keyof typeof icons;
export type IconSize = "big" | "medium" | "is-32" | "is-48" | "is-24" | "is-16" | "is-12";

const Icon: React.FC<
  {
    iconSize?: IconSize;
    isSpinning?: boolean;
    isCentered?: boolean;
    name: IconName;
    sizeInPx?: number;
  } & React.HTMLProps<HTMLDivElement>
> = ({ iconSize, isSpinning, isCentered, name, className, sizeInPx, ...props }) => {
  // @ts-ignore
  const IconSvg: any = icons[name];
  return (
    <div
      className={c(
        "icon",
        { clickable: props.onClick },
        { "is-48": iconSize && (iconSize === "big" || iconSize === "is-48") },
        { "is-24": iconSize && (iconSize === "medium" || iconSize === "is-24") },
        { "is-32": iconSize && iconSize === "is-32" },
        { "is-16": iconSize && iconSize === "is-16" },
        { "is-12": iconSize && iconSize === "is-12" },
        { spinner: isSpinning },
        { "is-centered": isCentered },
        className,
      )}
      {...props}
    >
      <IconSvg style={sizeInPx ? { width: sizeInPx, height: sizeInPx } : {}} />
    </div>
  );
};

export default Icon;
