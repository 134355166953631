import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import de from "./locales/de.json";
import fr from "./locales/fr.json";
import es from "./locales/es.json";
import pl from "./locales/pl.json";

const debug = process.env.NODE_ENV === "development";
const ns = ["app", "admin", "common"];
export const fallbackLng = "de";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    resources: { de, en, fr, es, pl } as any,
    fallbackLng,
    debug,
    ns,
    interpolation: { escapeValue: false },
    keySeparator: " ",
  });

export default i18n;
