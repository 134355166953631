import * as auth0 from "auth0-js";
import debug from "debug";
import jwt_decode from "jwt-decode";
import ability from "./ability";
import { AuthOptions } from "auth0-js";

const logger = debug("spt:auth");

const appHost = window.location.origin;

const config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: appHost + "/auth/callback",
  audience: "https://api.test-spt.de",
  responseType: "token",
  scope: "openid profile roles",
};

const rolesProperty = config.audience + "/roles";

export const ADMIN_ROLE = "admin";
export const GHOST_ROLE = "ghost";

type tokenPayload = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
} & {
  [key: string]: string[];
};

class Auth {
  webAuth: auth0.WebAuth;
  authentication: auth0.Authentication;
  profile?: auth0.Auth0UserProfile;
  accessToken?: string;
  expiresAt?: number;

  constructor() {
    this.webAuth = new auth0.WebAuth(config as AuthOptions);
    this.authentication = new auth0.Authentication(config as AuthOptions);
  }

  fetchProfile = (accessToken: string) => {
    return new Promise((resolve, reject) => {
      this.authentication.userInfo(accessToken, (error, userProfile) => {
        if (error != null) {
          console.error("Failed setting user profile", error);
          reject(error);
        } else {
          this.profile = userProfile;
          resolve(userProfile);
        }
      });
    });
  };

  get isAuthenticated(): Boolean {
    const check = this.accessToken != null && new Date().getTime() < this.expiresAt!;
    logger("authentication check: %o", check);
    return check;
  }

  signIn = (state?: string) => {
    this.webAuth.authorize({ language: "de", state });
  };

  handleCallback = (): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      // this parses the location fragment e.g. /callback/auth/#accessToken=...
      this.webAuth.parseHash((err, authResult) => {
        if (err) {
          console.error(err);
          return reject(err);
        }
        if (!authResult || !authResult.accessToken) {
          console.error(authResult);
          return reject(err);
        }
        this.setSession(authResult);
        resolve(authResult.state);
      });
    });
  };

  setSession = (authResult: any) => {
    const { accessToken, expiresIn } = authResult;
    this.accessToken = accessToken;
    this.expiresAt = new Date().getTime() + expiresIn * 1000;
    this.setAbilities(accessToken);

    // this.fetchProfile(accessToken);
  };

  setAbilities = (accessToken: string) => {
    let decodedToken = jwt_decode<tokenPayload>(accessToken);
    const rules = decodedToken.permissions.map(permission => {
      let [action, subject] = permission.split(":");
      return { actions: [action], subject: subject };
    });

    ability.update(rules);
  };

  signOut = () => {
    this.webAuth.logout({
      returnTo: appHost,
      clientID: config.clientID,
    });
  };

  getRoles = (accessToken: string) => {
    let decodedToken = jwt_decode<tokenPayload>(accessToken);
    return decodedToken[rolesProperty];
  };

  silentAuth = () => {
    return new Promise((resolve, reject) => {
      this.webAuth.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve(true);
      });
    });
  };
}

const auth = new Auth();

export default auth;
